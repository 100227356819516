import { GeoCoords } from './../../components/map-inview/map-inview.interface';

export interface MapDefaultSettings {
  center: GeoCoords;
  initZoom: number;
  maxZoom: number;
  minZoom: {
    labels: number;
    nearest: number;
  };
}

export const MAP_DEFAULT_SETTINGS: MapDefaultSettings = {
  center: {
    lat: 37.0902,
    lng: -95.7129,
  },
  initZoom: 4,
  maxZoom: 11,
  minZoom: {
    labels: 9,
    nearest: 8,
  },
};
