export enum InpatientReport {
  DISCHARGES_5_YR_PROJECTED_GROWTH = 'Inpatient Discharges 5 Yr Projected Growth',
  DISCHARGES_AGE = 'Inpatient Discharges - Age',
  DISCHARGES_PAYER = 'Inpatient Discharges - Payer',
  DAYS_5_YR_PROJECTED_GROWTH = 'Inpatient Days 5 Yr Projected Growth',
  AVERAGE_LENGTH_OF_STAY = 'Inpatient - Average Length of Stay',
  IN_VOLUME_DRIVERS = 'Inpatient - Volume Drivers'
}

export enum OutpatientReport {
  PROCEDURES_5_YR_PROJECTED_GROWTH = 'Outpatient Procedures - 5 Yr Projected Growth',
  PROCEDURES_AGE_BAND_SPLIT = 'Outpatient Procedures - Age Band Split',
  PROCEDURES_PAYER_MIX_SPLIT = 'Outpatient Procedures - Payer Mix Split',
  OUT_VOLUME_DRIVERS = 'Outpatient - Volume Drivers'
}

export enum PopulationReport {
  DEMOGRAPHIC_SNAPSHOT = 'Demographic Snapshot',
  INSURANCE_COVERAGE = 'Insurance Coverage',
  INSURANCE_COVERAGE_TOP_ZIP_CODES = 'Insurance Coverage - Top Zip Codes',
  POPULATION_GROWTH_AGE_GENDER = 'Population Growth - Age & Gender Split',
  POPULATION_GROWTH_TOP_ZIP_CODES = 'Population Growth - Top Zip Codes'
}

export enum EmergencyReport {
  SURGERY_PROCEDURES = 'Ambulatory Surgery Procedures - 5 Yr Growth',
  DEPT_VISITS = 'Emergency Dept. Visits - Payer Mix Split',
  DEPT_PROCEDURES = 'Emergency Dept. Procedures - Service Line Split'
}

export enum MarketShareReport {
  FACILITY_DATA = 'Facility Procedures Volume',
  ZIP_CODE_DATA = 'Market Procedures Volume'
}

export const chooseButtonMessage =
  'Please, choose a button to display the metric.';
export const chooseMarketAreaMessage = 'Please, select market area.';
export const chooseMarketAreaProceduresMessage =
  'Please, select market area and procedures.';
export const chooseFacilityProceduresMessage =
  'Please, select facility and procedures.';
export const noDataAvailableExpandMarket =
  'No data available. Please, extend the market area.';
export const noDataAvailable = 'No data available.';

export const MultiPagePNG = [
  OutpatientReport.PROCEDURES_5_YR_PROJECTED_GROWTH,
  OutpatientReport.OUT_VOLUME_DRIVERS,
  OutpatientReport.PROCEDURES_AGE_BAND_SPLIT,
  OutpatientReport.PROCEDURES_PAYER_MIX_SPLIT,
  InpatientReport.IN_VOLUME_DRIVERS,
  InpatientReport.DAYS_5_YR_PROJECTED_GROWTH,
  InpatientReport.DISCHARGES_5_YR_PROJECTED_GROWTH,
  InpatientReport.DISCHARGES_AGE,
  InpatientReport.DISCHARGES_PAYER,
  InpatientReport.AVERAGE_LENGTH_OF_STAY,
  MarketShareReport.FACILITY_DATA,
  MarketShareReport.ZIP_CODE_DATA,
];

export const verticalPNG = [EmergencyReport.DEPT_PROCEDURES, EmergencyReport.SURGERY_PROCEDURES];

export const AlwaysOnePNG = [
  'INSURANCE_COVERAGE_TOP_ZIP_CODES',
  'POPULATION_GROWTH_TOP_ZIP_CODES',
  'INSURANCE_COVERAGE',
  'POPULATION_GROWTH_AGE_GENDER',
  'DEMOGRAPHIC_SNAPSHOT',
  'DEPT_PROCEDURES',
  'DEPT_VISITS',
  'SURGERY_PROCEDURES',
];
