import { AppMainConfig } from './../../app/app.constants';
import { Component, Input, HostBinding, OnChanges } from '@angular/core';

@Component({
  selector: 'bottom-logo',
  templateUrl: './bottom-logo.html',
  styleUrls: ['./bottom-logo.scss'],
})
export class BottomLogoComponent implements OnChanges {
  @Input() alwaysOnBottom: boolean = false;
  public version: string = AppMainConfig.AppVersion;
  public hide: boolean = false;
  @HostBinding('class.hide') hideComponent: boolean;
  @HostBinding('class.bottom') fixedBottom: boolean;

  ngOnChanges() {
    this.hideComponent = this.hide;
    this.fixedBottom = this.alwaysOnBottom;
  }
}
