import { Component, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  chooseButtonMessage,
  EmergencyReport,
  InpatientReport,
  MarketShareReport,
  OutpatientReport,
  PopulationReport,
} from './inview-reports.interface';
import { Environment } from '~app.environment';
import { ExportOptionsComponent } from '../export-options/export-options.component';
import { GoogleAnalyticsProvider } from '../../../../providers/google-analytics/google-analytics.service';
import {
  GAEventActionMag,
  GAEventCategory,
} from '../../../../providers/google-analytics/google-analytics.interface';
import { AppState } from '~app.state';
import { InviewState } from '../../reducers/inview.reducer';

@Component({
  selector: 'inview-reports',
  templateUrl: './inview-reports.component.html',
  styleUrls: ['./inview-reports.component.scss'],
})
export class InviewReportsComponent {
  @ViewChild('refChart', { static: false }) refChartChild;
  public selectedInpatientSubcategory: InpatientReport;
  public selectedEmergencySubcategory: EmergencyReport;
  public selectedOutpatientSubcategory: OutpatientReport;
  public selectedPopulationSubcategory: PopulationReport;
  public selectedMarketShareData: MarketShareReport;
  public environment: string;
  public chooseButtonMessage = chooseButtonMessage;
  InpatientReport = InpatientReport;
  EmergencyReport = EmergencyReport;
  OutpatientReport = OutpatientReport;
  PopulationReport = PopulationReport;
  MarketShare = MarketShareReport;
  state$ = new Observable<InviewState>();
  GAEventActionMag = GAEventActionMag;

  ngOnInit() {
    this.state$ = this.store.select('inview');
  }

  constructor(
    private googleAnalytics: GoogleAnalyticsProvider,
    private modalController: ModalController,
    private readonly store: Store<AppState>
  ) {
    this.environment = Environment().AppEnvironment;
  }

  async toggleExportOptions() {
    const modal = await this.modalController.create({
      component: ExportOptionsComponent,
      cssClass: 'export-options-modal',
    });
    return await modal.present();
  }

  public getSubcategoryValues(enumValue: Record<string, any>) {
    return Object.values(enumValue);
  }

  public trackMagEvent(action: GAEventActionMag, label: string) {
    this.googleAnalytics.trackEvent(GAEventCategory.MAG, action, label);
  }
}
