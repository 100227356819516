import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Directive({ selector: '[baseReport]' })
export class BaseReport {
  constructor(public host: ElementRef) {}
  @Input() isExport = false;
  @Output() ready = new EventEmitter();

  // using it in export-options.component.ts
  public createHTML(title: string, uniqueId: string, html?): string {
    const shadow = this.host.nativeElement.shadowRoot;
    const insideHtml = html
      ? `${html.imageElement}` + `<style> ${html.style}</style>`
      : shadow.innerHTML;
    const titleToShow = title ? `<div class="png-title">${title} - ${uniqueId}</div>`
      : '';
    const printHtml =
        '<div class="image-padding">' +
        `${titleToShow}` +
        `${insideHtml}` +
        '</div>' +
        `<style> 
        .image-padding{
          padding: 20px;
        }
        .chart-container {
          border: unset !important;
          box-shadow: unset !important;
        }
        .hideWhenPrint { 
          display: none !important; 
        } 
        .png-title { 
          display: block !important; 
          height: 60px;
        } 
        .showWhenPrint {
          display: block !important; 
        }
        .exportWrapper { 
          display:flex; 
          flex-flow: column; 
        } 
      </style>`;
    return printHtml;
  }
}
