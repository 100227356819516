import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { InfoCardComponent } from './info-card';

@NgModule({
  declarations: [InfoCardComponent],
  imports: [IonicModule],
  exports: [InfoCardComponent],
})
export class InfoCardComponentModule {}
