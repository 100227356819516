export const VERTICAL_THRESHOLD = 3;

export enum VerticalChartType {
  SIMPLE,
  STACKED,
  STACKED_LABELS,
  GROUPED
}

export interface SimpleVerticalChartDataSet {
  values: {
    title: string;
    value: number;
    palette: string;
  }[];
  labels: string[];
}

export interface ComplexVerticalChartDataSet {
  values: {
    name: string;
    data: {
      title: string;
      label: string;
      value: number;
    }[];
    footer: string;
  }[];
  labels: string[];
  footerCaption?: string;
}

export const LIGHT_BLUE_BAR_COLOR = '#00B5E2';
export const DARK_BLUE_BAR_COLOR = '#005EB8';
export const HOVER_LIGHT_BLUE_BAR_COLOR = '#009CC9';
export const HOVER_DARK_BLUE_BAR_COLOR = '#00459F';
