import { Action, createReducer, on } from '@ngrx/store';
import {
  addMapCoords, clearInviewState,
  enterZipCodesList, getProceduresError, removeMapCoords,
  selectFacility, selectProcedure,
  selectZipCode, setMapBounds, setProcedures, setProceduresSpinner,
  setZipCodesNearby, setZipCodesSpinner,
  unselectFacility, unselectProcedure,
  unselectZipCode,
} from '../actions/inview.actions';
import { SingleFacility } from '../components/map-inview/map-inview.interface';
import { ProcedureType } from '../../../shared/models';

export type State = InviewState;

export interface MapState {
  selectedFacility?: SingleFacility;
  selectedZipcodes?: any[];
  nearbyZipcodes?: any[];
  coords: [];
  zipCodesLoading: boolean;
}

export interface ProceduresState {
  displayedProceduresInpatient?: any[];
  displayedProceduresOutpatient?: any[];
  selectedProceduresInpatient?: any[];
  selectedProceduresOutpatient?: any[];
  proceduresLoading: boolean;
}

export interface InviewState {
  map: MapState;
  procedures: ProceduresState;
}

export const initialState: any = {
  map: {
    selectedFacility: {},
    selectedZipcodes: [],
    nearbyZipcodes: [],
    coords: [],
    zipCodesLoading: false,
  },
  procedures: {
    displayedProceduresInpatient: [],
    displayedProceduresOutpatient: [],
    selectedProceduresInpatient: [],
    selectedProceduresOutpatient: [],
    proceduresLoading: false,
  },
};

function addUniqueZipCodes(stateList: any[], newList: any[]) {
  newList = newList.filter((newEle) => stateList
    .map((ele) => ele.code)
    .indexOf(newEle.code) === -1);
  return {
    state: [...stateList, ...newList],
    unique: [...newList],
  };
}

function removeElement(stateList: any[], newElement: any[]) {
  const index = stateList.indexOf(newElement);
  const newList = stateList.filter((el, i) => i !== index);
  return [...newList];
}

const inviewReducer = createReducer(
  initialState,
  on(setMapBounds, (state, action) => (
    {
      ...state,
      map: {
        ...state.map,
        coords: action.coords,
      },
    }
  )),

  on(addMapCoords, (state, action) => (
    {
      ...state,
      map: {
        ...state.map,
        coords: [...state.map.coords , action.coords],
      },
    }
  )),
  on(removeMapCoords, (state, action) => (
    {
      ...state,
      map: {
        ...state.map,
        coords: removeElement(state.map.coords, action.coords),

      },
    }
  )),
  on(selectFacility, (state, action) => (
    {
      ...state,
      map: {
        ...state.map,
        selectedFacility: action.facility,
      },
    }
  )),
  on(unselectFacility, (state) => (
    {
      ...state,
      map: {
        ...state.map,
        selectedFacility: {},
      },
    }
  )),
  on(selectZipCode, (state, action) => {
    const zipcodeState = {
      ...state,
      procedures: {
        ...state.procedures,
        proceduresLoading: true,
      },
      map: {
        ...state.map,
        selectedZipcodes: [...state.map.selectedZipcodes, action.zipCode],
      },
    };
    const existingElement = state.map.selectedZipcodes.find(( zipcode ) => zipcode.code === action.zipCode.code);
    if(!existingElement) {
      return zipcodeState;
    }
    else return state;
  }),

  on(unselectZipCode, (state, action) => (
    {
      ...state,
      procedures: {
        ...state.procedures,
        proceduresLoading: true,
      },
      map: {
        ...state.map,
        selectedZipcodes: state.map.selectedZipcodes.filter((zipcode) => zipcode.code !== action.zipCode.code),

      },
    }
  )),
  on(setZipCodesNearby, (state, action) => (
    {
      ...state,
      map: {
        ...state.map,
        nearbyZipcodes: [...state.map.nearbyZipcodes, ...action.zipCodes],

      },
    }
  )),
  on(enterZipCodesList, (state, action) => (
    {
      ...state,
      map: {
        ...state.map,
        selectedZipcodes: addUniqueZipCodes(state.map.selectedZipcodes, action.zipCodes).state,

      },
    }
  )),

  on(setProcedures, (state, action) => (
    {
      ...state,
      procedures: {
        ...state.procedures,
        proceduresLoading: false,
        displayedProceduresInpatient: action.proceduresIN || [],
        displayedProceduresOutpatient: action.proceduresOUT || [],
      },
    }
  )),

  on(setProceduresSpinner, (state, action) => (
    {
      ...state,
      procedures: {
        ...state.procedures,
        proceduresLoading: action.isSpinner,
      },
    }
  )),
  on(setZipCodesSpinner, (state, action) => (
    {
      ...state,
      map: {
        ...state.map,
        zipCodesLoading: action.isSpinner,
      },
    }
  )),

  on(clearInviewState, () => (
    { ...initialState }
  )),

  on(selectProcedure, (state, action) => {

    const inpatient = [];
    const outpatient = [];

    if(action.procedureType === ProcedureType.INPATIENT) {
      inpatient.push(action.name);
    }
    else {
      outpatient.push(action.name);
    }

    const newState = {
      ...state,
      procedures: {
        ...state.procedures,
        selectedProceduresInpatient: [...state.procedures.selectedProceduresInpatient, ...inpatient],
        selectedProceduresOutpatient: [...state.procedures.selectedProceduresOutpatient, ...outpatient],
      },
    };

    return newState;
  }),

  on(unselectProcedure, (state, action) => {

    let inpatientIND = -1;
    let outpatientIND = -1;

    if(action.procedureType === ProcedureType.INPATIENT) {
      inpatientIND = state.procedures.selectedProceduresInpatient.indexOf(action.name);
    }
    else {
      outpatientIND = state.procedures.selectedProceduresOutpatient.indexOf(action.name);
    }

    const newState = {
      ...state,
      procedures: {
        ...state.procedures,
        selectedProceduresInpatient: state.procedures.selectedProceduresInpatient.filter((el, i) => i !== inpatientIND),
        selectedProceduresOutpatient: state.procedures.selectedProceduresOutpatient.filter((el, i) => i !== outpatientIND),
      },
    };

    return newState;
  }),

  on(getProceduresError, (state) => (
    {
      ...state,
      procedures: {
        ...state.procedures,
        proceduresLoading: false,
      },
    }
  )),

);

export function reducer(state: State | undefined, action: Action) {
  return inviewReducer(state, action);
}