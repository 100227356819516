import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'footer-buttons',
  templateUrl: './footer-buttons.html',
  styleUrls: ['./footer-buttons.scss'],
})
export class FooterButtonsComponent {
  @Input() leftButtonName: string;
  @Input() rightButtonName: string;
  @Input() showLeftButtonSpinner: boolean = false;
  @Input() showRightButtonSpinner: boolean = false;
  @Input() disableLeftButton: boolean = false;
  @Input() disableRightButton: boolean = false;
  @Output()
  buttonClickEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  public buttonClick(buttonClicked: string) {
    return this.buttonClickEmitter.emit(buttonClicked);
  }
}
