import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { MagAnalyticsPage } from './mag-analytics';
import { AppHeaderComponentModule } from '../../components/app-header/app-header.module';
import { FooterButtonsComponentModule } from '../../components/footer-buttons/footer-buttons.module';
import { BottomLogoComponentModule } from '../../components/bottom-logo/bottom-logo.module';
import { MyDateRangePickerModule } from 'mydaterangepicker';
import { RegularButtonComponentModule } from '../../components/buttons/regular-button/regular-button.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MagAnalyticsRoutingModule } from './mag-analytics-routing.module';
import { SharedComponentsModule } from '../../shared/shared-components.module';
import { MatPaginatorModule } from '@angular/material';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { SimplePaginator } from '../../shared/simple-paginator/simple-paginator';

@NgModule({
  declarations: [MagAnalyticsPage],
  imports: [
    IonicModule,
    AppHeaderComponentModule,
    FooterButtonsComponentModule,
    BottomLogoComponentModule,
    MyDateRangePickerModule,
    RegularButtonComponentModule,
    NgxPaginationModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MagAnalyticsRoutingModule,
    SharedComponentsModule,
    MatPaginatorModule,
  ],
  providers: [{
    provide: MatPaginatorIntl,
    useClass: SimplePaginator,
  }],
})
export class MagAnalyticsPageModule {}
