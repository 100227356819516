import { Injectable } from '@angular/core';
import { TableModel } from '../../../../shared/models';

@Injectable()
export class InviewReportsProvider {
  public checkIfEmptyData(response: any): boolean {
    return !(
      Object.prototype.hasOwnProperty.call(response, 'data') &&
      Object.prototype.hasOwnProperty.call(response.data, 'dataset') &&
      Object.prototype.hasOwnProperty.call(response.data.dataset, 'values') &&
      response.data.dataset.values.length != 0
    );
  }

  public checkIfEmptyPageableData(response: any): boolean {
    return !(
      response.length != 0 &&
      Object.prototype.hasOwnProperty.call(response[0], 'data') &&
      Object.prototype.hasOwnProperty.call(response[0].data, 'dataset') &&
      Object.prototype.hasOwnProperty.call(
        response[0].data.dataset,
        'values'
      ) &&
      response[0].data.dataset.values.length != 0
    );
  }

  public mapSharedTablesRows(data: any): string[][] {
    const result: string[][] = [];
    const rows: any[] = data.dataset.values;
    rows.forEach((row, i) => {
      result[i] = row.rowValues;
    });
    return result;
  }

  public mapResponseToTables(response: any[]) {
    const allTables: TableModel[] = [];
    response.forEach((tbl) => {
      if (tbl.data) {
        const rows: string[][] = [];
        tbl.data.dataset.values.forEach(function(row) {
          rows.push(row.rowValues);
        });
        const tblToAdd: TableModel = {
          caption: tbl.title,
          headers: tbl.headers,
          rows: rows,
        };
        allTables.push(tblToAdd);
      }
    });
    return allTables;
  }
}
