import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { InviewActions } from '../actions';
import { InviewMapService } from '../providers/map-services/map-service';
import { InviewMapServiceMarkers } from '../providers/map-services/map-service-markers';
import { InviewMapServicePolygons } from '../providers/map-services/map-service-polygons';
import { InviewMapServiceBounds } from '../providers/map-services/map-service-bounds';
import { InviewMapServiceNearestZipcodes } from '../providers/map-services/map-service-nearestzipcodes';
import { GAEventActionMag, GAEventCategory } from '../../../providers/google-analytics/google-analytics.interface';
import { GoogleAnalyticsProvider } from '../../../providers/google-analytics/google-analytics.service';
import { AppState } from '~app.state';
import { InviewRestApi } from '../providers/inview-rest-api/inview-rest-api';
import { ProcedureType } from '../../../shared/models';
import { AlertsService } from '../../../providers/alerts-service';
import { fetchZipCodesNearby } from '../actions/inview.actions';
import { ZipCode } from '../components/map-inview/map-inview.interface';

@Injectable()

export class InviewEffects {

  private map;
  private facilityMarker;

  @Effect({ dispatch: false })
  inviewInitMap$ = this.actions$.pipe(
    ofType(InviewActions.setMap),
    tap(() => {
      this.map = this.mapService.loadMap();
    }),
  );

  @Effect({ dispatch: false })
  inviewShowFacilityMarker$ = this.actions$.pipe(
    ofType(InviewActions.selectFacility),
    tap(({ facility }) => {
      this.markersService.addFacilityMarker(this.map, facility);
      this.facilityMarker = this.markersService.markersOnMap;
      this.googleAnalyticsProvider.trackEvent(GAEventCategory.MAG, GAEventActionMag.SELECTED_FACILITIES, facility.name);
    }),
  );

  @Effect({ dispatch: false })
  inviewHideFacilityMarker$ = this.actions$.pipe(
    ofType(InviewActions.unselectFacility),
    tap(({ facility }) => {
      this.markersService.removeFacilityMarker(this.map, this.facilityMarker, facility);
    }),
  );

  @Effect({ dispatch: false })
  inviewShowZipCodePolygon$ = this.actions$.pipe(
    ofType(InviewActions.selectZipCode),
    tap(({ zipCode }) => {
      if(zipCode.shapes.length > 0) {
        this.polygonsService.addZipCodePolygon(this.map, zipCode, 'selected');
        // this.nearestService.getNearestZipcodes(zipCode.center);
        this.googleAnalyticsProvider.trackEvent(GAEventCategory.MAG, GAEventActionMag.SELECTED_ZIPCODES, zipCode.code+' - '+zipCode.name);
        // this.polygonsOnMap = this.polygonsService.polygonsOnMap;
      }

    }),
  );

  @Effect({ dispatch: false })
  inviewShowZipCodePolygons$ = this.actions$.pipe(
    ofType(InviewActions.enterZipCodesList),
    tap(({ zipCodes }) => {
      zipCodes.forEach((zipCode) => this.store$.dispatch(InviewActions.selectZipCode({ zipCode })));
    }),
  );

  @Effect({ dispatch: false })
  inviewHideZipCodePolygon$ = this.actions$.pipe(
    ofType(InviewActions.unselectZipCode),
    tap(({ zipCode }) => {
      const polygon = this.polygonsService.polygonsOnMap.find((ele) => ele.code === zipCode.code);
      this.polygonsService.removeZipCodePolygon(this.map, polygon, zipCode);     
    }),
  );

  @Effect({ dispatch: false })
  inviewMapSetZoom$ = this.actions$.pipe(
    ofType(InviewActions.addMapCoords),
    tap(() => {
      this.boundsService.zoomOrFitBound(this.map);
    }),
  );

  @Effect({ dispatch: false })
  inviewShowNearbyZipCodePolygons$ = this.actions$.pipe(
    ofType(InviewActions.setZipCodesNearby),
    tap(({ zipCodes }) => {
      this.polygonsService.showZipCodesPolygons(zipCodes, 'nearby', this.map);
    }),
  );

  @Effect()
  fetchZipCodesNearby$ = this.actions$.pipe(
    ofType(
      fetchZipCodesNearby
    ),
    debounceTime(1000),
    withLatestFrom(
      this.store$.select('inview'),
    ),
    switchMap(([{ coords, showNumber }, store]) => {
      return this.inviewRestApi
        .getNearestZipCodes(coords, showNumber)
        .pipe(
          map((res) => this.getUniqueZipCodes(store.map.nearbyZipcodes, res['data'].zipCodeList)),
          map((zipCodes: ZipCode[]) =>  InviewActions.setZipCodesNearby({ zipCodes: zipCodes })
          )
        );
    })
  )

  getUniqueZipCodes(stateList: any[], newList: any[]) {
    newList = newList.filter((newEle) => stateList
      .map((ele) => ele.code)
      .indexOf(newEle.code) === -1);
    return newList;
  }

  @Effect()
  fetchProcedures$ = this.actions$.pipe(
    ofType(
      InviewActions.selectZipCode,
      InviewActions.unselectZipCode,
      InviewActions.selectFacility,
      InviewActions.unselectFacility
    ),
    debounceTime(2000),
    withLatestFrom(
      this.store$.select('inview'),
    ),
    switchMap(([action, store]) => {
      return this.inviewRestApi.getProcedures(store.map.selectedZipcodes.map((zipcode) => zipcode.code), store.map.selectedFacility.id)
        .pipe(
          distinctUntilChanged(),
          map((res) => res.items),
          map((procedures) =>
            InviewActions.setProcedures({
              proceduresIN: procedures[ProcedureType.INPATIENT],
              proceduresOUT:procedures[ProcedureType.OUTPATIENT],
            })),
          catchError((error) => [InviewActions.getProceduresError({ error })])
        );
    }),
  );

  @Effect({ dispatch: false })
  logError$ = this.actions$.pipe(
    ofType(InviewActions.getProceduresError),
    tap(() => {
      this.alertsService.presentAlert('Problem with getting procedures.', 'Procedures');
    }),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<AppState>,
    private readonly mapService: InviewMapService,
    private readonly markersService: InviewMapServiceMarkers,
    private readonly polygonsService: InviewMapServicePolygons,
    private readonly boundsService: InviewMapServiceBounds,
    private readonly nearestService: InviewMapServiceNearestZipcodes,
    private readonly googleAnalyticsProvider: GoogleAnalyticsProvider,
    private readonly inviewRestApi: InviewRestApi,
    private readonly alertsService: AlertsService,
  ) { }
}
