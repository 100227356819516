import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'chart-footer',
  templateUrl: './footers.component.html',
  styleUrls: ['./footers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FootersComponent {
  @Input() titleLabel = '';
  @Input() total: number = 0;
  private _footerLabels: string[] = [];
  @Input()
  set footerLabels(v: string[]) {
    this._footerLabels = v.filter((label) => !!label);
  }
  get footerLabels() {
    return this._footerLabels;
  }
}
