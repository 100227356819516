import {
  Component,
  OnDestroy,
  OnInit,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { InviewRestApi } from '../../../../providers/inview-rest-api/inview-rest-api';
import { ZipCodeChangeHandler } from '../../shared/zip-code-change-handler.service';
import { ZipCodeConsumerComponent } from '../../../../../../shared/models';
import { Subscription } from 'rxjs';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  chooseMarketAreaMessage,
  noDataAvailableExpandMarket,
} from '../../inview-reports.interface';
import { InviewReportsProvider } from '../../inview-reports-provider';
import { BaseReport } from '../../base-report.component';

@Component({
  selector: 'population-growth-age-gender',
  templateUrl: './population-growth-age-gender.component.html',
  styleUrls: ['./population-growth-age-gender.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class PopulationGrowthAgeGenderComponent extends BaseReport
  implements OnInit, OnDestroy, ZipCodeConsumerComponent {
  public rowsFemale: string[][];
  public headersFemale: string[];
  public rowsMale: string[][];
  public headersMale: string[];
  public rowsBoth: string[][];
  public headersBoth: string[];
  public showSpinner = false;
  public zipCodes: string[];
  private dataSubscription: Subscription;
  public infoMessage: string;

  constructor(
    private restApi: InviewRestApi,
    private zipCodeChangeHandler: ZipCodeChangeHandler,
    private reportsProvider: InviewReportsProvider,
    public host: ElementRef
  ) {
    super(host);
  }

  ngOnInit(): void {
    this.zipCodeChangeHandler.registerComponent(this);
  }

  ngOnDestroy(): void {
    this.zipCodeChangeHandler.unregisterComponent(this);
    this.unsubscribeFetchData();
  }

  unsubscribeFetchData() {
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
  }

  fetchData() {
    this.unsubscribeFetchData();
    this.infoMessage = null;
    this.headersMale = null;
    this.rowsMale = null;
    this.headersFemale = null;
    this.rowsFemale = null;
    this.headersBoth = null;
    this.rowsBoth = null;
    if (this.zipCodes.length == 0) {
      this.infoMessage = chooseMarketAreaMessage;
    } else {
      this.showSpinner = true;
      this.dataSubscription = forkJoin([
        this.restApi.getPopulationGrowthAgeGender(this.zipCodes, 'F'),
        this.restApi.getPopulationGrowthAgeGender(this.zipCodes, 'M'),
        this.restApi.getPopulationGrowthAgeGender(this.zipCodes, ''),
      ])
        .pipe(
          finalize(() => {
            this.showSpinner = false;
            this.ready.emit();
          })
        )
        .subscribe((response: any) => {
          if (this.reportsProvider.checkIfEmptyData(response[0])) {
            this.infoMessage = noDataAvailableExpandMarket;
          } else {
            this.headersFemale = response[0].headers;
            this.rowsFemale = this.reportsProvider.mapSharedTablesRows(
              response[0].data
            );
            this.headersMale = response[1].headers;
            this.rowsMale = this.reportsProvider.mapSharedTablesRows(
              response[1].data
            );
            this.headersBoth = response[2].headers;
            this.rowsBoth = this.reportsProvider.mapSharedTablesRows(
              response[2].data
            );
          }
        });
    }
  }
}
