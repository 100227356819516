import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FacilitySelectionSearchComponent } from './facility-selection-search';
import { PipesModule } from '../../../../../pipes/pipes.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [FacilitySelectionSearchComponent],
  imports: [
    IonicModule,
    PipesModule,
    InfiniteScrollModule,
    CommonModule,
    FormsModule,
  ],
  exports: [FacilitySelectionSearchComponent],
})
export class FacilitySelectionSearchComponentModule {}
