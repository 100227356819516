export enum LocalStorageItem {
  SSO = 'sso',
  ADMIN = 'admin',
  START_TUTORIAL = 'startTutorial',
  START_PAGE_SETUP = 'startPageSetup',
  REFRESH_TOKEN = 'refreshToken',
  IS_CREDENTIALS = 'keychainCredentials',
}

export enum SessionStorageItem {
  SSO = 'sso',
  ADMIN = 'admin',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  INDUSTRY_FOCUS_GROUP_NAME = 'industryFocusGroupName',
  ACCESS_TOKEN = 'accessToken'

}
