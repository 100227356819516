import { Injectable } from '@angular/core';
import { pluck } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { InviewMapServiceNearestZipcodes } from './map-service-nearestzipcodes';
import { MAP_DEFAULT_SETTINGS } from './map.constants';
import { GeoCoords } from '../interfaces/map.interface';
import { InviewActions } from '../../actions';
import { AppState } from '~app.state';

@Injectable()
export class InviewMapServiceBounds {
  public coords;
  public allBoundsObject = null;

  constructor(
    private nearest: InviewMapServiceNearestZipcodes,
    private store: Store<AppState>
  ) {
    this.store.select('inview').pipe(pluck('map'))
      .subscribe((map) => {
        this.coords = map.coords;
      });
  }

  public addBound(coords: GeoCoords) {
    if (this.allBoundsObject === null) {
      this.allBoundsObject = new google.maps.LatLngBounds();
    }
    this.allBoundsObject.extend(coords);

    this.store.dispatch(InviewActions.addMapCoords({ coords }));
  }

  public removeBoundAndFitMap(
    map: google.maps.Map,
    coordsToRemove: GeoCoords,
    fit: boolean
  ) {
    //Build bounds without this coords
    this.allBoundsObject = new google.maps.LatLngBounds();
    this.coords.forEach((coords: GeoCoords) => {
      if (coords !== coordsToRemove) {
        this.allBoundsObject.extend(coords);
      } else {
        //Remove coordinates from selection state
        this.store.dispatch(InviewActions.removeMapCoords({ coords }));
      }
    });
    if (fit) {
      //If bounds empty, center map on init map center;
      this.zoomOrFitBound(map);
    }
  }

  public zoomOrFitBound(map: google.maps.Map ) {

    switch (this.coords.length) {
      case 0: {
        map.setZoom(8);
        break;
      }
      case 1: {
        map.setCenter(this.coords[0]);
        map.setZoom(10);
        break;
      }
      default: {
        map.fitBounds(this.allBoundsObject);
        break;
      }
    }
    this.fitMaxZoom(map);
  }

  private fitMaxZoom(map: google.maps.Map) {
    const currZoom = map.getZoom();
    const maxLevel = MAP_DEFAULT_SETTINGS.maxZoom;
    return currZoom > maxLevel - 1 ? map.setZoom(maxLevel) : null;
  }
}
