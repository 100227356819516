import { verticalPNG } from '../components/reports/inview-reports.interface';

export type FileType = {
  fileName: string;
  blob: Blob;
  data: string;
};

export class ImageGenerator {
  static getPNG(image, index): FileType {
    const fileName = `${index + 1}_${image.fileName.replace('.', '')}.png`;
    return {
      blob: image.blob,
      fileName,
      data: image.data,
    };
  }
  static getTXT(text: string, id: string): FileType {
    const fileName = `InfoPage-${id}.txt`;
    const blob = new Blob([text], { type: 'text/plain' });
    return {
      blob,
      fileName,
      data: null,
    };
  }
  static create(elementHTML: string, title: any) {
    const size =
        verticalPNG.indexOf(title) !== -1 ? {
          width: 800,
          height: 1280,
        } : null;
    const svg = this.createSvg(elementHTML);
    const canvas = this.createCanvas(size);
    const context = canvas.getContext('2d');
    context.fillStyle = '#fff';
    context.fillRect(0, 0, canvas.width, canvas.height);
    const svgBlob = new Blob([new XMLSerializer().serializeToString(svg)], { type: 'image/svg+xml' });
    return this.readAsDataURL(svgBlob).then((src) =>
      this.imageProcessor(src, context, canvas, title)
    );
  }

  static createSvg(innerHTML: string) {
    const xmlns = 'http://www.w3.org/2000/svg';
    const svg = document.createElementNS(xmlns, 'svg');
    const foreignObject = document.createElementNS(xmlns, 'foreignObject');
    foreignObject.setAttribute('width', '100%');
    foreignObject.setAttribute('height', '100%');

    foreignObject.innerHTML = innerHTML;
    svg.appendChild(foreignObject);

    return svg;
  }

  static createCanvas(size?: {
    width: number;
    height: number;
  }): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    canvas.width = size ? size.width : 1280;
    canvas.height = size ? size.height : 800;
    return canvas;
  }

  static imageProcessor(
    src: string,
    context: CanvasRenderingContext2D,
    canvas: HTMLCanvasElement,
    fileName: string
  ) {
    return new Promise<FileType>((resolve) => {
      const img = new Image();
      img.onload = () => {
        context.drawImage(img, 0, 0);
        resolve({
          fileName,
          blob: this.toBlobFromDataURL(canvas.toDataURL('image/png')),
          data: canvas.toDataURL('image/png'),
        });
      };
      img.src = src;
    });
  }

  static toBlobFromDataURL(dataUrl) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  static readAsDataURL = (blob: Blob) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onabort = reject;
      reader.onerror = reject;
      reader.onload = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
}
