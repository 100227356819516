import { mergeMap } from 'rxjs/operators';
import { Environment } from '../../app/app.environment';
import {
  ApiResponse,
  ApiPerson,
  ApiWhoAmI,
} from '../api-interfaces/rest.interface';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { RestMgmt } from '../rest-mgmt';
import { NativeStorageProvider } from '../native-storage/native-storage';
import {
  LocalStorageItem,
  SessionStorageItem,
} from '../native-storage/native-storage.interface';

@Injectable()
export class SsoService {
  private whoamiResultsSubject$: Subject<{sso: string; admin: boolean}> = new Subject();
  private personResultsSubject$: Subject<ApiPerson> = new Subject<ApiPerson>();

  constructor(
    private restMgmt: RestMgmt,
    private nativeStorage: NativeStorageProvider
  ) {}

  public getUserDetails() {
    return this.fetchWhoami()
      .pipe(
        mergeMap((result) => {
          return this.fetchPerson(result.sso);
        })
      )
      .subscribe();
  }

  public fetchWhoami() {
    this.updateWhoAmi();
    return this.whoamiResultsSubject$.asObservable();
  }

  updateWhoAmi() {
    this.restMgmt.getWhoAmI().subscribe((res: ApiResponse<ApiWhoAmI>) => {
      if (
        Environment().AppEnvironment !== 'prd' &&
        Environment().DefaultUser.useDefaultUser
      ) {
        this.nativeStorage.setLocalStorageItem(
          LocalStorageItem.SSO,
          Environment().DefaultUser.data.sso
        );
        this.nativeStorage.setSessionStorageItem(
          SessionStorageItem.SSO,
          Environment().DefaultUser.data.sso
        );
        this.nativeStorage.setLocalStorageItem(
          LocalStorageItem.ADMIN,
          Environment().DefaultUser.data.admin
        );
        this.nativeStorage.setSessionStorageItem(
          SessionStorageItem.ADMIN,
          Environment().DefaultUser.data.admin
        );
        this.whoamiResultsSubject$.next({
          sso: Environment().DefaultUser.data.sso,
          admin: Environment().DefaultUser.data.admin,
        });
      } else {
        const userData = res.data;
        if (userData.sso && userData.sso !== 'undefined' && userData.admin != null) {
          this.nativeStorage.setLocalStorageItem(
            LocalStorageItem.SSO,
            userData.sso
          );
          this.nativeStorage.setSessionStorageItem(
            SessionStorageItem.SSO,
            userData.sso
          );
          this.nativeStorage.setLocalStorageItem(
            LocalStorageItem.ADMIN,
            userData.admin
          );
          this.nativeStorage.setSessionStorageItem(
            SessionStorageItem.ADMIN,
            userData.admin
          );
          this.whoamiResultsSubject$.next({
            sso: userData.sso,
            admin: userData.admin,
          });
        } else {
          this.whoamiResultsSubject$.next({
            sso: this.nativeStorage.getSessionStorageItem(SessionStorageItem.SSO),
            admin: this.nativeStorage.getSessionStorageItem(SessionStorageItem.ADMIN),
          });
        }
      }
    });
  }

  public fetchPerson(sso: string) {
    this.restMgmt.getPerson(sso).subscribe((res: any) => {
      const user: ApiPerson = res.data.data[0];
      this.personResultsSubject$.next(user);
      if (user) {
        if (user.sso) {
          this.nativeStorage.setSessionStorageItem(
            SessionStorageItem.SSO,
            user.sso
          );
          this.nativeStorage.setLocalStorageItem(
            LocalStorageItem.SSO,
            user.sso
          );
        }
        if (user.firstName)
          this.nativeStorage.setSessionStorageItem(
            SessionStorageItem.FIRST_NAME,
            user.firstName
          );
        if (user.lastName)
          this.nativeStorage.setSessionStorageItem(
            SessionStorageItem.LAST_NAME,
            user.lastName
          );
        if (user.emailAddress)
          this.nativeStorage.setSessionStorageItem(
            SessionStorageItem.EMAIL,
            user.emailAddress
          );
        if (user.industryFocusGroupName)
          this.nativeStorage.setSessionStorageItem(
            SessionStorageItem.INDUSTRY_FOCUS_GROUP_NAME,
            user.industryFocusGroupName
          );
      }
    });
    return this.personResultsSubject$.asObservable();
  }
}
