import { InviewMapServiceLabels } from '../providers/map-services/map-service-labels';
import { InviewMapServiceNearestZipcodes } from '../providers/map-services/map-service-nearestzipcodes';
import { InviewMapServiceBounds } from '../providers/map-services/map-service-bounds';
import { InviewMapServicePolygons } from '../providers/map-services/map-service-polygons';
import { InviewMapServiceMarkers } from '../providers/map-services/map-service-markers';
import { InviewMapService } from '../providers/map-services/map-service';
import { ProceduresComponentModule } from '../components/pocedures-selection/procedures-selection.module';
import { MapInviewComponentModule } from '../components/map-inview/map-inview.module';
import { InviewMainComponent } from './inview-main';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { InviewFacilityService } from '../providers/inview-components-providers/facility-service';
import { InviewRestApi } from '../providers/inview-rest-api/inview-rest-api';
import { FacilitySelectionComponentModule } from '../components/facility-selection/facility-selection.module';
import { InfoCardComponentModule } from '../components/info-card/info-card.module';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material';
import { InviewReportsComponent } from '../components/reports/inview-reports.component';
import { SharedComponentsModule } from '../../../shared/shared-components.module';
import { RegularButtonComponentModule } from '../../../components/buttons/regular-button/regular-button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DemographicSnapshotComponent } from '../components/reports/population/demographic-snapshot/demographic-snapshot.component';
import { PopulationGrowthAgeGenderComponent } from '../components/reports/population/population-growth-age-gender/population-growth-age-gender.component';
import { InsuranceCoverageComponent } from '../components/reports/population/insurance-coverage/insurance-coverage.component';
import { PopulationGrowthTopZipCodesComponent } from '../components/reports/population/population-growth-top-zip-codes/population-growth-top-zip-codes.component';
import { ChartModule } from '../../../shared/charts/chart.module';
import { InsuranceCoverageTopZipCodesComponent } from '../components/reports/population/insurance-coverage-top-zip-codes/insurance-coverage-top-zip-codes.component';
import { InpatientDischargesComponent } from '../components/reports/inpatient/inpatient-discharges/inpatient-discharges.component';
import { InpatientDaysGrowthComponent } from '../components/reports/inpatient/inpatient-days-growth/inpatient-days-growth.component';
import { InpatientLengthOfStayComponent } from '../components/reports/inpatient/inpatient-length-of-stay/inpatient-length-of-stay.component';
import { InpatientVolumeDrivers } from '../components/reports/inpatient/inpatient-volume-drivers/inpatient-volume-drivers.component';
import { OutpatientProceduresGrowthComponent } from '../components/reports/outpatient/outpatient-procedures-growth/outpatient-procedures-growth.component';
import { OutpatientProceduresAgeComponent } from '../components/reports/outpatient/outpatient-procedures-age/outpatient-procedures-age.component';
import { OutpatientVolumeDrivers } from '../components/reports/outpatient/outpatient-volume-drivers/outpatient-volume-drivers.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { InpatientDischargesAgeComponent } from '../components/reports/inpatient/inpatient-discharges-age/inpatient-discharges-age.component';
import { InpatientDischargesPayerComponent } from '../components/reports/inpatient/inpatient-discharges-payer/inpatient-discharges-payer.component';
import { AmbulatorySurgeryProceduresComponent } from '../components/reports/emergency/ambulatory-surgery-procedures-5y-growth/ambulatory-surgery-procedures.component';
import { OutpatientProceduresPayerComponent } from '../components/reports/outpatient/outpatient-procedures-payer/outpatient-procedures-payer.component';
import { AreaSelectionModule } from '../components/area-selection/area-selection.module';
import { EmergencyDeptVisitsPayerComponent } from '../components/reports/emergency/emergency-dept-visits-payer/emergency-dept-visits-payer.component';
import { EmergencyDeptProceduresComponent } from '../components/reports/emergency/emergency-dept-procedures/emergency-dept-procedures.component';
import { ZipCodeChangeHandler } from '../components/reports/shared/zip-code-change-handler.service';
import { StateChangeHandler } from '../components/reports/shared/state-change-handler.service';
import { ExportOptionsComponent } from '../components/export-options/export-options.component';
import { BaseReport } from '../components/reports/base-report.component';
import { InviewReportsProvider } from '../components/reports/inview-reports-provider';
import { MarketShareModule } from '../components/market-share/market-share.module';

@NgModule({
  declarations: [
    InviewMainComponent,
    InviewReportsComponent,
    DemographicSnapshotComponent,
    PopulationGrowthAgeGenderComponent,
    InsuranceCoverageComponent,
    PopulationGrowthTopZipCodesComponent,
    InsuranceCoverageTopZipCodesComponent,
    InpatientDischargesComponent,
    InpatientDaysGrowthComponent,
    InpatientLengthOfStayComponent,
    InpatientVolumeDrivers,
    OutpatientProceduresGrowthComponent,
    OutpatientProceduresAgeComponent,
    OutpatientProceduresPayerComponent,
    OutpatientVolumeDrivers,
    InpatientDischargesAgeComponent,
    InpatientDischargesPayerComponent,
    AmbulatorySurgeryProceduresComponent,
    EmergencyDeptVisitsPayerComponent,
    EmergencyDeptProceduresComponent,
    ExportOptionsComponent,
    BaseReport,
  ],
  imports: [
    IonicModule,
    MapInviewComponentModule,
    ProceduresComponentModule,
    FacilitySelectionComponentModule,
    InfoCardComponentModule,
    CommonModule,
    MatExpansionModule,
    SharedComponentsModule,
    RegularButtonComponentModule,
    FormsModule,
    ChartModule,
    MatPaginatorModule,
    AreaSelectionModule,
    ReactiveFormsModule,
    AreaSelectionModule,
    MarketShareModule,
  ],
  providers: [
    InviewMapService,
    InviewFacilityService,
    InviewRestApi,
    InviewMapServiceMarkers,
    InviewMapServicePolygons,
    InviewMapServiceBounds,
    InviewMapServiceNearestZipcodes,
    InviewMapServiceLabels,
    ZipCodeChangeHandler,
    StateChangeHandler,
    InviewReportsProvider,
  ],
  exports: [
    InviewMainComponent,
    MapInviewComponentModule,
    ProceduresComponentModule,
    FacilitySelectionComponentModule,
    InfoCardComponentModule,
  ],
  entryComponents: [ExportOptionsComponent],
})
export class InviewMainComponentModule {}
