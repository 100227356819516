export type TAppExtEnvironment = 'stg' | 'prd' | 'dev';
export type AppEnvironment = TAppExtEnvironment | 'local';

export interface MainConfig {
  /*Set app name visible in app */
  AppName?: string;
  /* Set app version visible in app */
  AppVersion?: string;
  /* Set environment: */
  AppEnvironment: AppEnvironment;
  /* This parameter trun on/off device readyness check on initializiation */
  /* Set this false if you want to run $ ionic serve */
  EnablePlatformReady: boolean;
  /* Set secured host for backend api */
  DefaultUser: DefaultUserSettings;
}

export interface DefaultUserSettings {
  useDefaultUser: boolean;
  data?: {
    sso?: string;
    admin?: boolean;
  };
}

export interface DefaultUser {
  defaultUser: DefaultUserSettings;
}

export interface CodeLocationSettings {
  local: DefaultUser;
  external: DefaultUser;
}

export interface HostSettings {
  hostName: string;
  env: AppEnvironment;
  protocol?: string | 'http' | 'https';
  port?: string;
}

export const InternalExternalSettings: CodeLocationSettings = {
  local: {
    defaultUser: {
      useDefaultUser: false,
      data: {
        sso: '212212211',
        admin: true,
      },
    },
  },
  external: { defaultUser: { useDefaultUser: false }},
};