import { AppLogoComponent } from './app-logo';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [AppLogoComponent],
  imports: [IonicModule, CommonModule],
  exports: [AppLogoComponent],
})
export class AppLogoComponentModule {}
