import { Component, QueryList, ViewChildren } from '@angular/core';
import { IonCheckbox, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { pluck } from 'rxjs/operators';

import { ProcedureType } from '../../../../shared/models';
import { InviewRestApi } from '../../providers/inview-rest-api/inview-rest-api';
import { SubProceduresModalComponent } from './sub-procedures-modal/sub-procedures-modal.component';
import { AppState } from '~app.state';
import { InviewActions } from '../../actions';

@Component({
  selector: 'procedures-selection',
  templateUrl: './procedures-selection.html',
  styleUrls: ['./procedures-selection.scss'],
})
export class ProceduresComponent {
  @ViewChildren('inpatientCheckbox')
  public inpatientProceduresCheckbox: QueryList<IonCheckbox>;
  @ViewChildren('outpatientCheckbox')
  public outpatientProceduresCheckbox: QueryList<IonCheckbox>;
  public ProcedureType = ProcedureType;
  public selectedAllOutpatientProcedures = false;
  public selectedAllInpatientProcedures = false;

  procedures$: Observable<any>;

  constructor(
    private readonly modalController: ModalController,
    private inviewRestApi: InviewRestApi,
    private readonly store: Store<AppState>,
  ) {
    this.procedures$ = this.store.select('inview').pipe(pluck('procedures'));
  }

  onChangeCheckedCheckbox(name: string, procedureType: ProcedureType, isChecked: boolean) {
    if(isChecked) {
      this.store.dispatch(InviewActions.selectProcedure({
        name,
        procedureType,
      }));
    } else {
      this.store.dispatch(InviewActions.unselectProcedure({
        name,
        procedureType,
      }));
    }
  }

  async toggleSubProcedures(header: string, subProcedures: string) {
    const modal = await this.modalController.create({
      component: SubProceduresModalComponent,
      componentProps: {
        header,
        subProcedures,
      },
      cssClass: 'sub-procedures-modal',
    });
    return await modal.present();
  }

  fetchSubProcedures(groupName: string, procedureName: string) {
    this.inviewRestApi.getSubProcedures(groupName, procedureName)
      .subscribe( (response) => {
        this.toggleSubProcedures(procedureName, response['items']);
      });
  }

  toggleAllProcedures(checkboxes: QueryList<IonCheckbox>, selectAll: boolean) {
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked === selectAll) {
        checkbox.checked = !selectAll;
      }
    });
  }

  toggleAllProceduresByType(type: ProcedureType) {
    switch (type) {
      case ProcedureType.INPATIENT: {
        this.toggleAllProcedures(this.inpatientProceduresCheckbox, this.selectedAllInpatientProcedures);
        break;
      }
      case ProcedureType.OUTPATIENT: {
        this.toggleAllProcedures(this.outpatientProceduresCheckbox, this.selectedAllOutpatientProcedures);
        break;
      }
    }
  }
}
