import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideLabelsComponent } from './shared/labels/labels.component';
import { FootersComponent } from './shared/footers/footers.component';
import { ChartsModule } from 'ng2-charts';
import { PiechartComponent } from './piechart/piechart.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PaginationComponent } from '../charts-paging/pagination.component';
import { IonicModule } from '@ionic/angular';
import { VerticalChartComponent } from './vertical-chart/vertical-chart.component';

const components = [
  SlideLabelsComponent,
  VerticalChartComponent,
  FootersComponent,
  PiechartComponent,
  PaginationComponent,
];

@NgModule({
  imports: [
    ChartsModule,
    CommonModule,
    MatPaginatorModule,
    IonicModule,
  ],
  declarations: components,
  exports: components,
})
export class ChartModule {}
