import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { AuthGuard } from '~auth-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'mag',
    pathMatch: 'full',
  },
  {
    path: 'init',
    //canActivate: [AuthGuard],
    loadChildren: () =>
      import('../pages/init/init.module').then((m) => m.InitPageModule),
  },
  {
    path: 'error-505',
    loadChildren: () =>
        import('../pages/error-505/error-505.module').then(
          (m) => m.Error505PageModule
        ),
  },
  {
    path: 'about',
    loadChildren: () =>
        import('../pages/about/about.module').then(
          (m) => m.AboutPageModule
        ),
  },
  {
    path: 'mag',
    //canActivate: [AuthGuard],
    loadChildren: () =>
      import('../pages/inview/inview.module').then((m) => m.InviewPageModule),
  },
  {
    path: 'inview',
    //canActivate: [AuthGuard],
    loadChildren: () =>
      import('../pages/inview/inview.module').then((m) => m.InviewPageModule),
  },
  {
    path: 'admin-panel',
   //canActivate: [AuthGuard],
    loadChildren: () =>
        import('../pages/admin-panel/admin-panel.module').then(
          (m) => m.AdminPanelPageModule
        ),
  },
  {
    path: 'crowdsource',
    //canActivate: [AuthGuard],
    loadChildren: () =>
      import('../pages/crowdsource/crowdsource.module').then(
        (m) => m.CrowdSourcePageModule
      ),
  },
  {
    path: '**',
    redirectTo: 'mag',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
