export type MapEvent =
  | 'bounds_changed'
  | 'center_changed'
  | 'click'
  | 'dblclick'
  | 'drag'
  | 'dragend'
  | 'dragstart'
  | 'heading_changed'
  | 'idle'
  | 'maptypeid_changed'
  | 'mousemove'
  | 'mouseout'
  | 'mouseover'
  | 'projection_changed'
  | 'resize'
  | 'rightclick'
  | 'tilesloaded'
  | 'tilt_changed'
  | 'zoom_changed';

export interface ZipCodeMapPolygon {
  name?: string;
  code: string;
  center: GeoCoords;
  polygons: google.maps.Polygon /*any[] /* Array<Polygon> */;
}

export interface FacilityMarker {
  facilityId: number;
  marker: google.maps.Marker /* Array<Marker> */;
}

export interface Bounds {
  coords: GeoCoords[];
  allBoundsObject: google.maps.LatLngBounds /* Map Bounds */;
}

export interface PolygonLabelMarker {
  code: string;
  marker: google.maps.Marker /* Map Marker */;
}

export interface GeoCoords {
  lat: number;
  lng: number;
}
export const MAX_ZIP_CODE_SELECTION_LIMIT = 150;