import { Component, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import * as R from 'ramda';
import { debounceTime } from 'rxjs/operators';

import { FileType, ImageGenerator } from '../../helpers/imageGennerator';
import { BaseReport } from '../reports/base-report.component';
import {
  EmergencyReport,
  InpatientReport,
  MultiPagePNG,
  OutpatientReport,
  PopulationReport,
  AlwaysOnePNG, MarketShareReport,
} from '../reports/inview-reports.interface';
import { InviewRestApi } from '../../providers/inview-rest-api/inview-rest-api';
import { GoogleAnalyticsProvider } from '../../../../providers/google-analytics/google-analytics.service';
import { GAEventActionMag, GAEventCategory } from '../../../../providers/google-analytics/google-analytics.interface';
import { ReportSection, ReportSections } from './export-options.interface';
import { AppState } from '~app.state';
import { InviewState } from '../../reducers/inview.reducer';

@Component({
  selector: 'export-options',
  templateUrl: './export-options.component.html',
  styleUrls: ['./export-options.component.scss'],
})
export class ExportOptionsComponent implements OnInit {
  @ViewChildren(BaseReport) chosenReports;
  public AlwaysOnePNG = AlwaysOnePNG;
  public exportOptionsForm: FormGroup;
  public reportsLabels;
  public PopulationReport = PopulationReport;
  public InpatientReport = InpatientReport;
  public OutpatientReport = OutpatientReport;
  public EmergencyReport = EmergencyReport;
  public MarketShareReport = MarketShareReport;
  public readyReportsArray = [];
  public uniqueId: string;
  public isExportInProgress = false;

  public isReportReady$ = new Subject<boolean>();
  public isCreateZip$ = new Subject<boolean>();
  public store$ = new Observable<InviewState>();

  public reportSections = ReportSections;

  constructor(
    public formBuilder: FormBuilder,
    private readonly modalCtrl: ModalController,
    private readonly inviewRest: InviewRestApi,
    private readonly googleAnalytics: GoogleAnalyticsProvider,
    private readonly store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.reportSections.forEach((report) => report.checkedAll = false);
    this.exportOptionsForm = this.formBuilder.group(
      this.generateReportsRef(this.reportSections)
    );
    this.reportsLabels = this.generateMergedLabels(this.reportSections);
    this.isReportReady$.pipe(debounceTime(1000)).subscribe(() => {
      this.readyReportsArray.sort();
      if (
        R.equals(
          this.readyReportsArray,
          this.getExistingKeys(this.exportOptionsForm)
        )
      ) {
        this.isExportInProgress = false;
      }
    });

    this.store$ = this.store.select('inview');
  }

  onExportSubmit() {
    this.renderFromChildrenView();
  }

  trackExportReportEvent(report: string) {
    this.googleAnalytics.trackEvent(GAEventCategory.MAG, GAEventActionMag.EXPORTED_REPORT, report);
  }

  renderFromChildrenView() {
    this.uniqueId = Date.now().toString();
    this.isCreateZip$.next(true);
    const arr: Promise<FileType>[] = [];
    this.chosenReports._results.forEach((report) => {
      const reportHost = report.host.nativeElement;
      this.trackExportReportEvent(reportHost.title);
      if (MultiPagePNG.indexOf(reportHost.title) !== -1) {
        let style = '';
        reportHost.shadowRoot
          .querySelectorAll('style')
          .forEach((styleShadow: HTMLElement) => {
            style = style.concat(styleShadow.innerHTML);
          });
        reportHost.shadowRoot
          .querySelectorAll('.multiple-png')
          .forEach((eleImg) => {
            this.fillArray(arr, report, reportHost, {
              imageElement: eleImg.innerHTML,
              style,
            });
          });
      } else {
        this.fillArray(arr, report, reportHost);
      }
    });
    Promise.all(arr).then((images) => {
      const textFile = ImageGenerator.getTXT(
        this.generateInfoText(this.uniqueId),
        this.uniqueId
      );
      const imageFiles = [];
      images.forEach((image, index) =>
        imageFiles.push(ImageGenerator.getPNG(image, index))
      );

      this.getZipFile(this.createFormData(imageFiles, textFile));
    });
  }

  createFormData(imageFiles, textFile): FormData {
    const formData = new FormData();
    formData.append('files', textFile.blob, textFile.fileName);
    imageFiles.forEach((imageFile) =>
      formData.append('files', imageFile.blob, imageFile.fileName)
    );
    return formData;
  }

  getZipFile(formData: FormData) {
    this.inviewRest.uploadAndZip(formData).subscribe(
      (res: any) => {
        const blob = new Blob([res], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        const download = document.createElement('a');
        download.href = url;
        download.download = `MagReport_${this.uniqueId}.zip`;
        download.click();
        window.URL.revokeObjectURL(url);
        this.isCreateZip$.next(false);
        this.dismissModal();
      },
      () => {
        this.isCreateZip$.next(false);
      }
    );
  }

  generateMergedLabels(reportSections: ReportSection[]) {
    const obj = {};
    reportSections.forEach((report) => {
      Object.assign(obj, { [report.name]: this.generateReportLabels(report.value) });
    });
    return obj;
  }

  generateReportLabels(reportSection: ReportSection) {
    return Object.values(reportSection).map((report, index) => {
      return {
        displayName: report,
        ref: Object.keys(reportSection)[index],
      };
    });
  }

  generateReportsRef(reportSections: ReportSection[]) {
    const obj = {};
    reportSections.forEach((reportSection) =>
      this.generateReportLabels(reportSection.value).forEach((labelObject) => {
        Object.assign(obj, { [labelObject.ref]: [false] });
      })
    );
    return obj;
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  private fillArray(arrToFill: any[], metric, metricHost, html?) {
    arrToFill.push(
      ImageGenerator.create(
        metric.createHTML(metricHost.title, this.uniqueId, html),
        metricHost.title
      )
    );
  }

  onReady(reportTitle: string) {
    if (this.getExistingKeys(this.exportOptionsForm).indexOf(reportTitle) !== -1) {
      this.readyReportsArray.push(reportTitle);
      this.isReportReady$.next();
    }
  }

  onCheck(title: string, isChecked: boolean, section: ReportSection) {
    if (isChecked) {
      this.isExportInProgress = true;
      const checkedReports = Object.keys(section.value).filter((report)=> (this.exportOptionsForm.get(report).value === false));
      if (checkedReports.length === 0) {
        section.checkedAll = true;
      }
      return;
    } else {
      section.checkedAll = false;
    }
    this.readyReportsArray = this.readyReportsArray.filter(
      (item) => item !== title
    );
    this.isReportReady$.next();
  }

  onCheckAll(section: ReportSection) {
    setTimeout(() => {
      section.checkedAll=!section.checkedAll;
      Object.keys(section.value).forEach( (report) => {
        this.exportOptionsForm.get(report).setValue(section.checkedAll);
      });
    });
  }

  getExistingKeys(form: FormGroup): string[] {
    return Object.keys(form.value).filter((key) => form.value[key]).sort();
  }

  private generateInfoText(id: string): string {
    let text='';
    this.store$.subscribe((state) => {
      let zipCodes = '';
      let inProcedures = '';
      let outProcedures = '';
      const date = new Date();
      state.map.selectedZipcodes.forEach(
        (zipcode) =>
          (zipCodes = zipCodes.concat(`${zipcode.name}(${zipcode.code}), `))
      );
      state.procedures.selectedProceduresInpatient.forEach(
        (proc) => (inProcedures = inProcedures.concat(proc + ', \n'))
      );
      state.procedures.selectedProceduresOutpatient.forEach(
        (proc) => (outProcedures = outProcedures.concat(proc + ', \n'))
      );
      text = `Generated information for id ${id}.

Date: ${date}

Facility Name: ${state.map.selectedFacility ? state.map.selectedFacility.name : 'N/A'},
Facility Zip Code: ${state.map.selectedFacility ? state.map.selectedFacility.zipCode : 'N/A'},
Facility Address: ${state.map.selectedFacility ? state.map.selectedFacility.address : 'N/A'}
  
Zip Codes: ${zipCodes}

Procedures Inpatient:
${inProcedures}
Procedures Outpatient:
${outProcedures}
`;
    });
    return text;
  }
}
