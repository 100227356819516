import { ShareMgmt } from './share-mgmt';
import { Injectable } from '@angular/core';

import { RestMgmt } from './rest-mgmt';
import { ApiResponse } from './api-interfaces/rest.interface';
import { NativeStorageProvider } from './native-storage/native-storage';

@Injectable()
export class CrowdMgmt {
  constructor(private restMgmt: RestMgmt, private shareMgmt: ShareMgmt) {}

  sendRequest(subject: string, requestText: string) {
    return new Promise((resolve, reject) => {

      this.restMgmt
      // .postCrowdRequest(this.createCrowdsourceBody(subject, requestText, sender))
      .postCrowdRequest(this.createCrowdsourceBody(subject, requestText))
      .subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );

      // this.shareMgmt.getSender().then(
      //   (res: ApiResponse<any>) => {
      //     const sender = res.data.data[0];



      //     this.restMgmt
      //       .postCrowdRequest(this.createCrowdsourceBody(subject, requestText, sender))
      //       .subscribe(
      //         (response) => {
      //           resolve(response);
      //         },
      //         (error) => {
      //           reject(error);
      //         }
      //       );



      //   },
      //   (err) => {
      //     reject(err);
      //   }
      // );
    });
  }

  // createCrowdsourceBody(reqSub: string, reqText: string, user: any) {
    createCrowdsourceBody(reqSub: string, reqText: string) {
    // user = this.parseEmployeeObject(user);
    const storageObj = new NativeStorageProvider();
    console.log('User',storageObj.getLocalStorageItem('sso'));
    return {
      user: storageObj.getLocalStorageItem('sso'),
      subject: reqSub,
      message: reqText,
    };
  }

  parseEmployeeObject(employee: any) {
    return {
      name: employee.firstName + ' ' + employee.lastName,
      sso: employee.sso,
      email: employee.emailAddress,
    };
  }
}
