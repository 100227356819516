import { Platform } from '@ionic/angular';
import { GoogleAnalyticsProvider } from '../providers/google-analytics/google-analytics.service';
import { Router } from '@angular/router';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { SsoService } from '../providers/loginProviders/sso-service';
import { RestMgmt } from '../providers/rest-mgmt';
import { Environment } from './app.environment';
import { LoginService } from '../providers/loginProviders/login-service';
import { SessionStorageItem } from '../providers/native-storage/native-storage.interface';
import { NativeStorageProvider } from '../providers/native-storage/native-storage';
import { OAuthService, JwksValidationHandler, NullValidationHandler, } from 'angular-oauth2-oidc';
import { authConfig } from './sso.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.html',
  styleUrls: ['./app.scss'],
})

export class MyApp implements OnInit, AfterViewChecked {

  isAdmin = false;
  public mainapp;
  constructor(
    private oauthService: OAuthService,
    private platform: Platform,
    private restMgmt: RestMgmt,
    private ga: GoogleAnalyticsProvider,
    private ssoService: SsoService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly nativeStorage: NativeStorageProvider,
    public loginService: LoginService,
  ) {
    this.configureWithoutDiscovery();   
  }

  ngOnInit() {
    this.checkIfIE();
  }

  private configureWithoutDiscovery() {
    this.oauthService.configure(authConfig);
    this.oauthService.tryLogin();
    if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
      this.oauthService.tokenValidationHandler = new NullValidationHandler();
      this.oauthService.loadDiscoveryDocument(window.location.origin + '/assets/openid-config.json')
        .then(doc => this.oauthService.initImplicitFlow()
        ).catch(
          (data) => {
            console.log("Test--", data);           
          }
        );
    } else {
      // redirect to a page that needs no authentication     
      if (window.location.toString().indexOf('.com//') !== -1) {
        const newUrl = window.location.toString().replace('.com//', '.com/');
        window.location.replace(newUrl);
      }
      this.loginService.isHeader$.next(false);
      this.platformReadyInit();
    }
  }  

  ngAfterViewChecked() {
    this.isAdmin = this.nativeStorage.getSessionStorageItem(SessionStorageItem.ADMIN) === 'true';
    this.cdr.detectChanges();
  }

  async platformReadyInit() {    
    this.setEnvironment();
    if (Environment().EnablePlatformReady) {
      this.platform.ready().then(
        async () => {
          this.mainapp = await this.initializeApp();
        },
        () => {
          console.error('Platform not ready');
        }
      );
    } else {
      await this.initializeApp();
    }    
  }

  async initializeApp() {
    this.loginService.isHeader$.next(true);
    this.ssoService.getUserDetails();
    this.ga.setAccount(Environment().AppEnvironment);
    return true;
  }

  private checkIfIE() {
    if (this.detectIE()) {
      alert(
        'We’re sorry but your browser does not support all the SalesIQ features. Please use Chrome or Safari browser.'
      );
    }
  }

  private setEnvironment() {
    this.restMgmt.setEnvironment(Environment().AppEnvironment);
  }

  /**
   * detect IE
   * returns version of IE or false, if browser is not Internet Explorer
   */
  private detectIE() {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }
}


