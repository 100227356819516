import { BottomLogoComponent } from './bottom-logo';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [BottomLogoComponent],
  imports: [IonicModule, CommonModule],
  exports: [BottomLogoComponent],
})
export class BottomLogoComponentModule {}
