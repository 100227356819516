import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'highlightPipe' })
export class HighlightPipe implements PipeTransform {
  transform(value: any, match: any): any {
    const re = new RegExp(match, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
    value = value.replace(
      re,
      (match) => `<span class="highlight">${match}</span>`
    );

    return value;
  }
}
