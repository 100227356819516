import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '../../providers/loginProviders/login-service';

@Component({
  selector: 'error-505',
  templateUrl: './error-505.html',
  styleUrls: ['./error-505.scss'],
})
export class Error505Page implements OnInit, OnDestroy {

  public message = 'The SalesIQ application will not be available for 2 hours';
  constructor(private readonly loginService: LoginService) {
    this.loginService.isHeader$.next(false);
  }


  ngOnInit() {
    this.message = history.state.message;
  }

  ngOnDestroy() {
    this.loginService.isHeader$.next(true);
  }
}
