import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoginService {
  
  public loginSuccessSource = new Subject();
  public isHeader$ = new Subject();

  constructor(
  ) {   
  } 
}
