import { Injectable, NgZone } from '@angular/core';

import { InviewMapServiceNearestZipcodes } from './map-service-nearestzipcodes';
import { PolygonLabelMarker, ZipCodeMapPolygon } from '../interfaces/map.interface';
import { MAP_DEFAULT_SETTINGS } from './map.constants';

@Injectable()
export class InviewMapServiceLabels {

  private labelsOnMap: any[] = [];
  constructor(
    private nearest: InviewMapServiceNearestZipcodes,
    private _ngZone: NgZone,
  ) {}

  public zoomChange(map, minZoom) {
    const showPolygons = this.nearest.showIfZoom(minZoom, map);
    this.showHidePolygonLabels(map);
    if(showPolygons) {
      this.nearest.getNearestZipcodes(map.getCenter().toJSON());
    }
  }

  private showHidePolygonLabels(map) {
    const show = this.nearest.showIfZoom(
      MAP_DEFAULT_SETTINGS.minZoom.labels,
      map
    );
    this.labelsOnMap.forEach((label) => label.marker.setVisible(show));
  }

  public showPolygonLabels(map, allPolygons: ZipCodeMapPolygon[]) {
    const allLabels: PolygonLabelMarker[] = [];

    allPolygons.forEach((polygon) => {
      if (polygon) {
        const marker = this.addPolygonLabel(map, polygon);
        allLabels.push({
          code: polygon.code,
          marker,
        });
      }
    });
    this.labelsOnMap = [...this.labelsOnMap, ...allLabels];
    this.showHidePolygonLabels(map);
  }

  public addPolygonLabel(
    map: google.maps.Map,
    polygon: ZipCodeMapPolygon
  ): google.maps.Marker /*Marker*/ {
    const label = new google.maps.Marker({
      position: polygon.center,
      icon: {
        url: '',
        size: new google.maps.Size(0, 0),
      },
      label: {
        text: polygon.code,
        color: '#015EB8',
        fontSize: '12px',
        fontWeight: 'bold',
      },
      visible: false,
    });

    label.setMap(map);

    return label;
  }
}
