import { FooterButtonsComponent } from './footer-buttons';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [FooterButtonsComponent],
  imports: [IonicModule, CommonModule],
  exports: [FooterButtonsComponent],
})
export class FooterButtonsComponentModule {}
