import { Component, Input, HostBinding, OnChanges } from '@angular/core';

@Component({
  selector: 'regular-button',
  templateUrl: './regular-button.html',
  styleUrls: ['./regular-button.scss'],
})
export class RegularButtonComponent implements OnChanges {
  @Input() clicked: boolean = false;
  @Input() buttonText: string;
  @Input() iconName: string;
  @Input() disabledBeforeActive: boolean = false;
  @Input() grayedOutOnClick: boolean = false;
  @Input() showSpinner: boolean = false;
  @Input() disabled: boolean = false;
  @HostBinding('class.turned-on') isClicked: boolean;
  @HostBinding('class.disabled') isDisabled: boolean;
  @HostBinding('class.grayed-out') isGrayedOut: boolean;
  public spinnerLightColor: boolean = true;

  constructor() {
    this.clicked
      ? (this.spinnerLightColor = false)
      : (this.spinnerLightColor = true);
  }

  ngOnChanges() {
    this.isClicked = this.clicked && !this.disabledBeforeActive;
    this.isDisabled =
      (this.clicked && this.disabledBeforeActive) ||
      this.showSpinner ||
      this.disabled;

    this.clicked
      ? (this.spinnerLightColor = false)
      : (this.spinnerLightColor = true);
    this.isGrayedOut = this.clicked && this.grayedOutOnClick;
  }

  public toggleButton() {
    this.isClicked = !this.isClicked;
    return this.isClicked;
  }
}
