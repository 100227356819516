import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input() currentPage: number;
  @Input() totalSize: number;
  @Output() changedPage = new EventEmitter();

  constructor() {}

  onChangeHandle(pageIndex) {
    this.currentPage = pageIndex;
    this.changedPage.emit(pageIndex);
  }
}
