import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  ProcedureAndZipCodeConsumerComponent,
  ProcedureType,
} from '../../../../../shared/models';
import { AppState } from '~app.state';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable()
export class StateChangeHandler {
  private components: Set<ProcedureAndZipCodeConsumerComponent> = new Set();
  private subs: Subscription;
  constructor(
    private readonly store: Store<AppState>
  ) {}

  public registerComponent(component: ProcedureAndZipCodeConsumerComponent) {
    this.components.add(component);
    this.subs = this.stateSubscription(component);

  }

  public unregisterComponent(component: ProcedureAndZipCodeConsumerComponent) {
    this.subs.unsubscribe();
    const componentsSet = this.components;
    componentsSet.forEach(function(c) {
      if (c.constructor == component.constructor) {
        componentsSet.delete(c);
      }
    });
  }

  stateSubscription(c) {
    return this.store.select('inview')
      .pipe(
        distinctUntilChanged(),
        debounceTime(2000)
      )
      .subscribe( (data) => {
        c.facilityId = data.map.selectedFacility.id;
        c.zipCodes = data.map.selectedZipcodes.map((zip) => zip.code);
        this.setProceduresOfComponent(c, data.procedures);
        c.fetchData();
      });
  }

  private setProceduresOfComponent(component: ProcedureAndZipCodeConsumerComponent, procedures) {

    switch (component.procedureType) {
      case ProcedureType.INPATIENT: {
        component.procedures.inpatientProcedures = procedures.selectedProceduresInpatient;
        break;
      }
      case ProcedureType.OUTPATIENT: {
        component.procedures.outpatientProcedures = procedures.selectedProceduresOutpatient;
        break;
      }
      case ProcedureType.ALL: {
        component.procedures.inpatientProcedures = procedures.selectedProceduresInpatient;
        component.procedures.outpatientProcedures = procedures.selectedProceduresOutpatient;
        break;
      }
    }
  }
}
