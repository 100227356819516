import { Input, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { ChartOptions, ChartScales, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { CHART_WIDTH } from '../../constants/constants';

const totalizer = {
  id: 'totalizer',
  beforeUpdate: (chart: any) => {
    const totals: { [key: number]: number } = {};
    const utmost: number[] = [];

    chart.data.datasets.forEach((dataset: any, datasetIndex: number) => {
      if (chart.isDatasetVisible(datasetIndex)) {
        dataset.data.forEach((value: number = 0, index: number) => {
          totals[index] = (totals[index] || 0) + value;
          if (value !== 0) {
            utmost[index] = datasetIndex;
          }
        });
      }
    });
    chart.$totalizer = {
      totals: totals,
      utmost: utmost,
    };
  },
};

export abstract class ChartComponent implements OnChanges {
  @Input() title = '';
  @Input() xAxisTitle = '';
  @Input() yAxisTitle = '';
  @Input() widthRatio = 1;
  @Input() heightRatio = 1;
  @ViewChild('img', { static: true })
  img?: ElementRef;
  @ViewChild('chart', { static: true })
  chart?: ElementRef;

  public minYAxis = 0;
  public datasets: ChartDataSets[] = [];
  public chartLabels: string[] = [];
  public footerLabels: string[];
  public titleLabel: string;
  public pluginsConfig: Chart.ChartPluginsOptions = {
    datalabels: {
      anchor: 'end',
      align: 'top',
      font: {
        size: 16,
        weight: 'bold',
      },
      formatter: (_value: any, context: any) =>
        context.chart.$totalizer.totals[context.dataIndex],
      display: (context: any) =>
        context.datasetIndex ===
        context.chart.$totalizer.utmost[context.dataIndex],
    },
  };

  public options: ChartOptions = {
    responsive: false,
    layout: {
      padding: {
        top: 80,
        left: 80,
      },
    },
    maintainAspectRatio: false,
    tooltips: {
      cornerRadius: 4,
      caretSize: 4,
      xPadding: 16,
      yPadding: 10,
      mode: 'index',
      itemSort(
        itemA: Chart.ChartTooltipItem,
        itemB: Chart.ChartTooltipItem,
      ): number {
        return itemB.datasetIndex - itemA.datasetIndex;
      },
      backgroundColor: 'rgba(17,152,210,0.9)',
      titleFontStyle: 'normal',
      titleMarginBottom: 15,
    },
  };

  public plugins = [pluginDataLabels, totalizer];

  public readonly colorScheme = Object.freeze([
    '#00B5E2',
    '#005EB8',
    '#FF8A00',
    '#33CCB1',
    '#EFC000',
    '#84A5AD',
    '#FF5555',
    '#5057FF',
    '#81B052',
    '#CE40C0',
    '#85E0D0',
    '#FFB966',
    '#66D3EE',
    '#F5D966',
    '#669ED4',
    '#B5C9CE',
    '#FF9999',
    '#969AFF',
    '#B4D097',
    '#E28CD9',
    '#C2F0E8',
    '#FFDCB3',
    '#B3E9F7',
    '#FBEDB3',
    '#B3CFEA',
    '#DBE4E7',
    '#FFCCCC',
    '#CBCDFF',
    '#DAE8CC',
    '#F1C6ED',
  ]);

  public colors: any[] = this.colorScheme.map((color) => ({
    backgroundColor: color,
    borderColor: '#fff',
    borderWidth: { bottom: 2 },
    borderSkipped: false,
  }));
  public labels: string[] | undefined = [];

  public scales = (
    xAxisTitle: string,
    yAxisTitle: string,
    yPrefix = '',
    ySuffix = '',
    minYAxis = 0
  ): ChartScales => ({
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: yAxisTitle,
      },
      ticks: {
        fontSize: 14,
        min: minYAxis,
        precision: 0,
        callback: (value: string) => `${yPrefix}${value}${ySuffix}`,
      } as Chart.TickOptions,
    }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: xAxisTitle,
      },
      ticks: { fontSize: 14 },
      gridLines: { display: false },
    }],
  });

  protected setUp(prefix = '', postfix = '', config = this.pluginsConfig) {
    if (!this.chart) {
      throw new Error('Chart is not defined');
      return;
    }
    const canvasElement = this.chart.nativeElement;
    canvasElement.setAttribute('width', `${CHART_WIDTH / this.widthRatio}px`);
    return new Promise<{}>((resolve, reject) => {
      return (this.options = {
        ...this.options,
        animation: {
          ...this.options.animation,
          onComplete: ({ chart: { ctx: { canvas }}}: any) => {
            if (this.img) {
              this.img.nativeElement.src = canvas.toDataURL();
              resolve();
            } else {
              reject();
            }
          },
        },
        scales: this.scales(
          this.xAxisTitle || '',
          this.yAxisTitle || '',
          prefix,
          postfix,
          this.minYAxis
        ),
        plugins: config,
      });
    });
  }

  async ngOnChanges() {
    return this.setUp();
  }
}
