import { Component } from '@angular/core';

@Component({
  selector: 'info-card',
  templateUrl: 'info-card.html',
  styleUrls: ['./info-card.scss'],
})
export class InfoCardComponent {
  constructor() {}
}
