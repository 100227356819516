import { createAction, props } from '@ngrx/store';

import { SingleFacility, ZipCode } from '../components/map-inview/map-inview.interface';
import { Procedures } from '../../../shared/models';

export const setMap = createAction(
  '[Inview] SetMap'
);

export const selectFacility = createAction(
  '[Inview] Select Facility',
  props<{ facility: SingleFacility }>()
);

export const unselectFacility = createAction(
  '[Inview] Unselect Facility',
  props<{ facility: SingleFacility }>()
);

export const selectZipCode = createAction(
  '[Inview] Select ZipCode',
  props<{ zipCode: ZipCode }>()
);
export const unselectZipCode = createAction(
  '[Inview] Unselect ZipCode',
  props<{ zipCode: ZipCode }>()
);

export const enterZipCodesList = createAction(
  '[Inview] Enter ZipCode List',
  props<{ zipCodes: ZipCode[] }>()
);

export const setZipCodesNearby = createAction(
  '[Inview] Set ZipCodes Nearby',
  props<{ zipCodes: ZipCode[] }>()
);

export const setProceduresSpinner = createAction(
  '[Inview] Set Procedures Spinner',
  props<{ isSpinner: boolean }>()
);

export const setZipCodesSpinner = createAction(
  '[Inview] Set Procedures Spinner',
  props<{ isSpinner: boolean }>()
);

export const fetchZipCodesNearby = createAction(
  '[Inview] fetch ZipCodes Nearby',
  props<{ coords: any; showNumber: number }>()
);

export const setMapBounds = createAction(
  '[Inview] Set Map Bounds',
  props<{ coords: any }>()
);

export const addMapCoords = createAction(
  '[Inview] Add Map Coords',
  props<{ coords: any }>()
);

export const removeMapCoords = createAction(
  '[Inview] Remove Map Coords',
  props<{ coords: any }>()
);

export const setProcedures = createAction(
  '[Inview] Set Procedures',
  props<{ proceduresIN: Procedures[]; proceduresOUT: Procedures[]}>()
);

export const selectProcedure = createAction(
  '[Inview] Select Procedure',
  props<{name: any; procedureType: any }>()
);

export const unselectProcedure = createAction(
  '[Inview] Unselect Procedure',
  props<{name: any; procedureType: any }>()
);

export const getProceduresError = createAction(
  '[Inview] Error',
  props<{ error: any }>()
);

export const clearInviewState = createAction(
  '[Inview] Clear Inview State'
);
