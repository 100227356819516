import { Injectable } from '@angular/core';

declare let localStorage, sessionStorage;

@Injectable()
export class NativeStorageProvider {
  constructor() {}

  public setLocalStorageItem(ref: string, value: any): void {
    localStorage.setItem(ref, this.parseToStorage(value));
  }

  public getLocalStorageItem(ref: string): any {
    return this.parseFromStorage(localStorage.getItem(ref));
  }

  public setSessionStorageItem(ref: string, value: any): void {
    sessionStorage.setItem(ref, this.parseToStorage(value));
  }

  public getSessionStorageItem(ref: string): any {
    return this.parseFromStorage(sessionStorage.getItem(ref));
  }

  private parseToStorage(unparsedItem: any): string {
    let parsedItem: string;

    if (typeof unparsedItem === 'string') {
      parsedItem = unparsedItem;
    } else {
      parsedItem = JSON.stringify(unparsedItem);
    }

    return parsedItem;
  }

  private parseFromStorage(unparsedItem: any): any {
    let parsedItem: string;

    if (this.checkIfUndefined(unparsedItem)) {
      parsedItem = undefined;
    } else if (this.checkIfNull(unparsedItem)) {
      parsedItem = null;
    } else if (this.checkIfStringObj(unparsedItem)) {
      parsedItem = JSON.parse(unparsedItem);
    } else {
      parsedItem = unparsedItem;
    }

    return parsedItem;
  }

  private checkIfUndefined(unparsedItem: string): boolean {
    return unparsedItem === undefined && typeof unparsedItem === 'undefined';
  }

  private checkIfNull(unparsedItem: string): boolean {
    return unparsedItem === null && typeof unparsedItem === 'object';
  }

  private checkIfStringObj(unparsedItem: string): boolean {
    return unparsedItem.includes('[') || unparsedItem.includes('{');
  }
}
