export enum GAEventCategory {
  ASSETS = 'Assets',
  MAG = 'Mag',
  LINKS = 'Links',
  TEMPLATE_SHARE = 'Template share',
  ASSET_SHARE = 'Asset share'
}

export enum GATrackScreen {
  MAG = 'Mag',
  HOME_PAGE = 'Home Page',
  ACCOUNT_EXECUTIVES = 'Account Executives',
  NOTIFICATION_HISTORY = 'Notification History',
  ASSETS = 'Assets',
  CROWDSOURCE = 'Crowdsource',
  SHARE = 'Share',
  PROPOSAL_REORDER = 'Proposal Reorder',
  OFFLINE_ASSETS = 'Offline Assets'
}

export enum GAEventActionLinks {
  BLOGS = 'Blogs',
  NEWS = 'News',
  AE = 'AE',
  OUTCOMES = 'OUTCOMES'
}

export enum GAEventActionAssets {
  SHARED = 'Shared',
  SHARED_EXTERNALLY = 'Shared externally',
  SHARED_INTERNALLY = 'Shared internally',
  SUBSCRIBED_BY_CATEGORY = 'Subscribed by category',
  MERGED_BY_ASSET = 'Merged by asset (count asset merges)',
  MERGING_EVENTS_COUNT = 'Merged (count merging events)',
  DOWNLOADED_OFFLINE_MODE = 'Downloaded to offline mode',
  OPENED_BY_PATH = 'Opened by path',
  OPENED_BY_ASSET = 'Opened by asset',
  OPENED_BY_ASSET_PATH = 'Opened by path & asset'
}

export enum GAEventActionMag {
  CHART_SELECTED = 'Selected chart report',
  SECTION_EXPANDED = 'Expanded section',
  SELECTED_FACILITIES = 'Selected facilities',
  SELECTED_ZIPCODES = 'Selected zip-codes',
  EXPORTED_REPORT = 'Exported report'
}

export function GAEventActionTemplatedShare(template: string) {
  return `Templated email - ${template}.`;
}

export function GAEventActionAssetShare(isExternal: boolean, modality: string, assetName: string) {
  const type = isExternal ? 'EXT' : 'INT';
  return `${type} Modality: ${modality}, Asset: ${assetName}`;
}

export function GAEventLabelTemplatedShare(recipient: string, isExternal = false) {
  if (isExternal) {
    return `External email - ${recipient}.`;
  }
  return `Recipient - ${recipient}.`;

}
