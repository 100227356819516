import { ApiEndpoints } from './api-interfaces/rest.interface';
import { Injectable } from '@angular/core';
import { IHttp } from './loginProviders/i-http';
import { environment } from 'src/environments/environment.ts';

@Injectable()
export class RestMgmt {
  environment: string;
  endpointRoot: any = {
    dev: environment.bkndUrl,
    stg: environment.bkndUrl,
    prd: environment.bkndUrl,
  };

  public static endpointName: ApiEndpoints = {
    crowdsource: 'sendEmail',
    magAnalyticsReport: 'admin/reports/magPlus',
    magAnalyticsExcel: 'admin/reports/magPlus/excel',
    searchPeopleSSO: 'searchPeople/',
    whoami: 'whoami',
    getUserID: 'ga/id',
  };

  constructor(public iHttp: IHttp) { }

  setEnvironment(
    environment = 'prd',
  ) {
    this.environment = environment;
  }

  getConfig(url: string) {
    return (this.endpointRoot[this.environment] + url);
  }

  postCrowdRequest(body) {
    return this.iHttp.post(
      this.getConfig(RestMgmt.endpointName.crowdsource),
      body
    );
  }

  public getWhoAmI() {
    return this.iHttp.get(this.getConfig(RestMgmt.endpointName.whoami)
    );
  }

  public getPerson(sso: string) {
    return this.iHttp.get(
      this.getConfig(RestMgmt.endpointName.searchPeopleSSO) + sso
    );
  }

  getUserGoogleAnalyticsID() {
    return this.iHttp.get(this.getConfig(RestMgmt.endpointName.getUserID), { responseType: 'text' });
  }

  getMagAnalyticsReport(pageIndex: number, reportFrom: string, reportTo: string) {
    let params = '?page=' + pageIndex;
    if (reportFrom) {
      params += '&reportFrom=' + reportFrom;
    }
    if (reportTo) {
      params += '&reportTo=' + reportTo;
    }
    return this.iHttp.get(
      this.getConfig(RestMgmt.endpointName.magAnalyticsReport) + params
    );
  }

  downloadFile(data: any) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
    });
    const url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.download = "MAGAnalyticsReport.xlsx";
    anchor.href = url;
    anchor.click();
  }

  getMagAnalyticsExcel(reportFrom: string, reportTo: string) {
    this.iHttp.getBlobResponse(
      this.getConfig(RestMgmt.endpointName.magAnalyticsExcel + '?reportFrom=' + reportFrom + '&reportTo=' + reportTo
      )).subscribe(data => this.downloadFile(data)),
      error => console.log('Error downloading the file.'),
      () => console.info('OK');
  }
}
