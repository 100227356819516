import { Injectable } from '@angular/core';
import { RestMgmt } from './rest-mgmt';
import { ApiPerson } from './api-interfaces/rest.interface';


declare let localStorage;

@Injectable()
export class ShareMgmt {

  constructor(
    private readonly restMgmt: RestMgmt,
  ) {}

  getSender() {
    return new Promise((resolve, reject) => {
      this.restMgmt.getPerson(localStorage.sso).subscribe(
        (res: ApiPerson[]) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
