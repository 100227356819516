import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'sub-procedures-modal',
  templateUrl: './sub-procedures-modal.component.html',
  styleUrls: ['./sub-procedures-modal.component.scss'],
})
export class SubProceduresModalComponent {
  public header;
  public subProcedures;

  constructor(private readonly modalCtrl: ModalController) {}

  dismissModal() {
    this.modalCtrl.dismiss();
  }

}
