import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { MyApp } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  HttpXhrBackend,
} from '@angular/common/http';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';
import { Network } from '@ionic-native/network/ngx';
import { CookieModule, CookieService } from 'ngx-cookie';
import { CommonModule, DatePipe } from '@angular/common';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { IonicImageLoader } from 'ionic-image-loader';
import { QuillModule } from 'ngx-quill';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { RestMgmt } from '../providers/rest-mgmt';
import { SsoService } from '../providers/loginProviders/sso-service';
import { IHttp } from '../providers/loginProviders/i-http';
import { StartupService } from './startup-service';
import { AlertsService } from '../providers/alerts-service';
import { NativeStorageProvider } from '../providers/native-storage/native-storage';
import { AppRoutingModule } from '~app-routing.module';
import { BottomLogoComponentModule } from '../components/bottom-logo/bottom-logo.module';
import { ErrorIntercept } from '../interceptors/error.interceptor';
import { GoogleAnalyticsProvider } from '../providers/google-analytics/google-analytics.service';
import { InviewMainComponentModule } from '../app_modules/inview/inview-main/inview-main.module';
import { InitPageModule } from '../pages/init/init.module';
import { reducers } from '~app.state';
//import { environment } from '../environments/environment';
import { InviewEffects } from '../app_modules/inview/effects/inview.effects';
import { CrowdMgmt } from '../providers/crowd-mgmt';
import { AdminPanelPageModule } from '../pages/admin-panel/admin-panel.module';
import { CrowdSourcePageModule } from '../pages/crowdsource/crowdsource.module';
import { AppHeaderComponentModule } from '../components/app-header/app-header.module';
import { LoginService } from '../providers/loginProviders/login-service';
import { ShareMgmt } from '../providers/share-mgmt';
import { LoaderService } from '../providers/loader-service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment.ts';

export function startupServiceFactory(
  startupService: StartupService
): Function {
  return () => startupService.load();
}

export function httpFactory(httpClient: HttpClient): IHttp {
  return new IHttp(httpClient);
}

@NgModule({
  declarations: [MyApp],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    InviewMainComponentModule,
    CookieModule.forRoot(),
    CommonModule,
    AppRoutingModule,
    AdminPanelPageModule,
    AppHeaderComponentModule,
    CrowdSourcePageModule,
    BottomLogoComponentModule,
    IonicImageLoader.forRoot(),
    QuillModule.forRoot(),
    OAuthModule.forRoot(),
    InitPageModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([InviewEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.logOnlyFlag,
    }),
  ],
  bootstrap: [MyApp],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      deps: [Router],
      multi: true,
    },
    // {
    //   //to do
    //   provide: auth_oidc,     
    //   multi: true,
    // },
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot) =>
      {
        window.location.href = (route.data as any).externalUrl;
      },
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    GoogleAnalytics,
    {
      provide: ErrorHandler,
      useClass: ErrorHandler,
    },
    SafariViewController,
    RestMgmt,
    CrowdMgmt,
    ShareMgmt,
    GoogleAnalyticsProvider,
    {
      provide: IHttp,
      deps: [HttpClient, HttpXhrBackend],
      useFactory: httpFactory,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [StartupService],
      multi: true,
    },
    StartupService,
    SsoService,
    Network,
    AlertsService,
    CookieService,
    LoginService,
    LoaderService,
    NativeStorageProvider,
    WebView,
    DatePipe,
  ],
})
export class AppModule {}
