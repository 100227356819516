import { Moment } from 'moment';

export interface PieChartComponentData {
  title: string;
  value: number;
  palette: string;
}

export interface DateRange {
  startDate: Moment;
  endDate: Moment;
}

export enum DisplayType {
  Number = 0,
  Perc = 1
}

export class TableModel {
  caption: string;
  headers: string[];
  rows: string[][];

  constructor(caption: string, headers: string[], rows: string[][]) {
    this.caption = caption;
    this.headers = headers;
    this.rows = rows;
  }
}

interface DataFetchingComponent {
  fetchData();
}

export interface ZipCodeConsumerComponent extends DataFetchingComponent {
  zipCodes: string[];
}

export enum ProcedureType {
  INPATIENT = 'inpatient',
  OUTPATIENT = 'outpatient',
  ALL = 'all'
}

export interface Procedures {
  outpatientProcedures: string[];
  inpatientProcedures: string[];
}

export interface ProcedureAndZipCodeConsumerComponent extends DataFetchingComponent {
  zipCodes: string[];
  procedures: Procedures;
  procedureType: ProcedureType;
}

export interface FacilityConsumerComponent extends DataFetchingComponent {
  facilityId: number;
}
