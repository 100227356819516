import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '~app.state';
import { InviewActions } from '../../actions';
import { InviewMapService } from '../../providers/map-services/map-service';

@Component({
  selector: 'map-inview',
  templateUrl: 'map-inview.html',
  styleUrls: ['./map-inview.scss'],
})
export class MapInviewComponent implements OnInit, AfterViewInit {

  constructor(
    private map: InviewMapService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.initializeMap();
  }

  private initializeMap() {
    this.store.dispatch(InviewActions.setMap());
  }
}
