import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor,
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

export class ErrorIntercept implements HttpInterceptor {

  constructor(
    private readonly router: Router,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 505) {
          this.router.navigate(['./error-505'], { state: { message: error.error.message || '2' }}); // navigate to maintenance page
          return EMPTY;
        }

        if (error.status === 401) {
          location.reload(); // refresh page if session expires
          return EMPTY;
        }

        let errorMessage = '';
        if (error.error instanceof ErrorEvent || error.error) {
          // client-side error or  error with meaningful message - e.g. exception  at BE
          errorMessage = `Error: ${error.error.message || error.error.error}`;
        } else {
          // server-side error
          errorMessage = `Server error: ${error.status}\nMessage: ${
            error.message
          }`;
        }
        return throwError(errorMessage);
      })
    );
  }
}
