import { RegularButtonComponent } from './regular-button';

import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [RegularButtonComponent],
  imports: [IonicModule, CommonModule],
  exports: [RegularButtonComponent],
})
export class RegularButtonComponentModule {}
