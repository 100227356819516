import {
  Component,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as R from 'ramda';
import { DisplayType, PieChartComponentData } from '../../models';

interface Label {
  position: number;
  percent: number;
  end: number;
  value: number;
}

@Component({
  selector: 'piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiechartComponent implements OnChanges {
  @Input() title = '';
  @Input() displayType: DisplayType = DisplayType.Number;
  @Input() dataSet: { values: PieChartComponentData[] } = { values: [] };
  @Input() definitionIdSlide = '';
  get data(): number[] {
    return this.dataSet.values.map(({ value }) => value);
  }
  get dataLabels(): string[] {
    return this.dataSet.values.map(({ title }) => title);
  }
  get totalHours(): number {
    return R.sum(this.data);
  }

  pieStyle: any;
  labels: Label[] = [];
  colors = [
    '#005EB8',
    '#00B5E2',
    '#003466',
    '#33CCB1',
    '#FF8A00',
    '#EFC000',
    '#84A5AD',
    '#FF5555',
    '#5057FF',
    '#81B052',
    '#CE40C0',
    '#85E0D0',
    '#FFB966',
    '#66D3EE',
    '#F5D966',
    '#669ED4',
    '#B5C9CE',
    '#FF9999',
    '#969AFF',
    '#B4D097',
    '#E28CD9',
  ];

  constructor(public sanitizer: DomSanitizer) {}

  ngOnChanges() {
    const str: string[] = [];
    const total: number = this.totalHours;
    const labels: Label[] = [];
    let cur = 0;
    const minValue = 0.01 * total;
    const values = this.data.map(
      (value) => (value < minValue ? minValue : value)
    );
    const sumTotal = R.sum(values);

    values.forEach((value: number, i: number) => {
      const percent = value / sumTotal * 100;
      const real = this.data[i] / total * 100;
      str.push(`${this.colors[i]} ${cur}% ${cur + percent}%`);
      labels.push({
        position: cur + percent / 2,
        percent: this.displayType ? this.data[i] : Math.round(real * 10) / 10,
        end: cur,
        value: this.data[i],
      });
      cur += percent;
    });

    this.labels = labels;

    if (str.length > 0) {
      const gradient = new (window as any).ConicGradient({ stops: str.join(', ') });
      this.pieStyle = { background: `url(${gradient.dataURL})` };
    }
  }

  calculateRotation(position: number) {
    return position * 3.6;
  }
}
