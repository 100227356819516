import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '../../providers/loginProviders/login-service';

@Component({
  selector: 'about',
  templateUrl: './about.html',
  styleUrls: ['./about.scss'],
})
export class AboutPage implements OnInit, OnDestroy {

  public message = 'Under Construction';
  constructor(private readonly loginService: LoginService) {
    this.loginService.isHeader$.next(false);
  }


  ngOnInit() {
    this.message = history.state.message;
  }

  ngOnDestroy() {
    this.loginService.isHeader$.next(true);
  }
}
