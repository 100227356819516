import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment.ts';

export const authConfig: AuthConfig = {

  // Url of the Identity Provider  
  issuer: environment.ssoIssuerUrl,

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin,
  
  // The SPA's id. The SPA is registerd with this id at the auth-server  
  clientId: environment.ssoClientId,
  
  dummyClientSecret: environment.ssoClientSecret,

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: environment.ssoScope,

  //responseType: 'code',
  showDebugInformation: true,
  //requireHttps: false,
  strictDiscoveryDocumentValidation: false,

}