import { Injectable, NgZone } from '@angular/core';

import { MapEvent } from './../interfaces/map.interface';
import { InviewMapServiceBounds } from './map-service-bounds';
import { SingleFacility } from '../../components/map-inview/map-inview.interface';

@Injectable()
export class InviewMapServiceMarkers {
  private infoWindowOpen: any = null;
  public markersOnMap: any = null;

  constructor(
    private bounds: InviewMapServiceBounds,
    private _ngZone: NgZone
  ) {}

  public addFacilityMarker(map: google.maps.Map, facility: SingleFacility) {
    const marker = new google.maps.Marker({
      position: facility.coordinates,
      title: facility.name,
      animation: google.maps.Animation.DROP,
    });
    this.bounds.addBound(facility.coordinates);
    this.addListenerFacility(map, marker, facility, 'click');
    this.markersOnMap = marker;
    return marker.setMap(map);
  }

  public removeFacilityMarker(
    map: google.maps.Map,
    marker: any,
    facility: SingleFacility
  ) {
    this.bounds.removeBoundAndFitMap(map, facility.coordinates, false);
    this.markersOnMap = null;
    return marker.setMap(null);
  }

  private addListenerFacility(
    map: google.maps.Map,
    marker: google.maps.Marker,
    facility1: SingleFacility,
    mapEvent: MapEvent
  ) {
    return marker.addListener(mapEvent, () => {
      let facility;
      this._ngZone.run(() => {
        facility = facility1;
        if (this.infoWindowOpen) {
          this.infoWindowOpen.close();
          this.infoWindowOpen = null;
        } else {
          this.openFacilityWindow(map, marker, facility);
        }
      });
    });
  }
  private openFacilityWindow(
    map: google.maps.Map,
    marker,
    facility: SingleFacility
  ) {
    //todo: fix image on google map -> enable static map option in google account. Need to create new account. No access to old password.
    const apiURL = `https://maps.googleapis.com/maps/api/staticmap?center=${facility.name},${facility.address}&zoom=16&size=150x150&maptype=hybrid&key=AIzaSyAGILiTyZWuEY13ajhOaeOY-EhpFwfp_Eg`;
    const contentString = `
        <div class="iw-title">${facility.name}</div>
        <img src="${apiURL}">
        <div class="iw-details">
          <div><span>Location: </span>${facility.coordinates.lng} ${
  facility.coordinates.lat
}</div>
          <div><span>State: </span>${facility.state}</div>
          <div><span>Zipcode: </span>${facility.zipCode}</div>
          <div><span>Address: </span>${facility.address}</div>
      </div>
        `;
    const infoWindow = new google.maps.InfoWindow();
    this.infoWindowOpen = infoWindow;
    infoWindow.setContent(contentString);
    infoWindow.open(map, marker);
  }
}
