export const environment = {
   bkndUrl: 'https://stg-gehc-mag-backend.herokuapp.com/salesiq/inview/',
   ssoIssuerUrl: 'https://efs.sso.gehealthcare.com/fss',  
   ssoClientId: 'GEHC_MAG_STG', 
   ssoClientSecret: 'HerokuMAG@2024', 
   ssoScope: 'openid api profile',    
   logOnlyFlag: 'false',
   hostNameUrl: 'mag-stage.apps.ge.com',
   googleAnalyticsId: 'UA-56292200-20',
};
