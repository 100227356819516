import { Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FacilityConsumerComponent,
  ProcedureAndZipCodeConsumerComponent,
  Procedures,
  ProcedureType,
} from '../../../../../shared/models';
import {
  SimpleVerticalChartDataSet,
  VerticalChartType,
} from '../../../../../shared/charts/vertical-chart/vertical-chart.interface';
import { StateChangeHandler } from '../../reports/shared/state-change-handler.service';
import { InviewRestApi } from '../../../providers/inview-rest-api/inview-rest-api';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {
  chooseFacilityProceduresMessage,
  noDataAvailable,
} from '../../reports/inview-reports.interface';
import { BaseReport } from '../../reports/base-report.component';

@Component({
  selector: 'facility-market-share',
  templateUrl: './facility-market-share.html',
  styleUrls: ['./facility-market-share.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,

})
export class FacilityMarketShareComponent
  extends BaseReport
  implements
      OnInit,
      OnDestroy,
      ProcedureAndZipCodeConsumerComponent,
      FacilityConsumerComponent {
  public currentPage: number = 1;
  public dataSetPage: SimpleVerticalChartDataSet;
  public showSpinner = false;
  public zipCodes: string[];
  public allValues: any[];
  public procedures: Procedures = {
    inpatientProcedures: [],
    outpatientProcedures: [],
  };
  public procedureType = ProcedureType.ALL;
  public facilityId;
  public infoMessage: string;
  private dataSubscription: Subscription;
  VerticalChartType = VerticalChartType;

  ngOnInit(): void {
    this.stateChangeHandler.registerComponent(this);
  }

  ngOnDestroy(): void {
    this.stateChangeHandler.unregisterComponent(this);
    this.unsubscribeFetch();
  }

  constructor(
    private stateChangeHandler: StateChangeHandler,
    private restApi: InviewRestApi,
    public host: ElementRef
  ) {
    super(host);
  }

  public fetchData() {
    this.unsubscribeFetch();
    this.infoMessage = null;
    this.dataSetPage = null;
    if (
      this.facilityId != null &&
      (this.procedures.inpatientProcedures.length > 0 ||
        this.procedures.outpatientProcedures.length > 0)
    ) {
      this.showSpinner = true;
      this.dataSubscription = this.restApi
        .getMarketShareDataByFacility(
          this.facilityId,
          this.procedures.inpatientProcedures,
          this.procedures.outpatientProcedures
        )
        .pipe(
          finalize(() => {
            this.showSpinner = false;
            this.ready.emit();
          })
        )
        .subscribe((response: any[]) => {
          if (Object.keys(response[0]).length === 0) {
            this.infoMessage = noDataAvailable;
          } else {
            this.allValues = response
              .map((res) => {
                const data = {
                  values: res.data.dataset.values,
                  labels: ['Inpatient', 'Outpatient'],
                };
                return data;
              } )
            ;
            this.pageChanged(this.currentPage);
          }
        });
    } else {
      this.infoMessage = chooseFacilityProceduresMessage;
    }
  }

  public pageChanged(pageNumber) {
    this.dataSetPage = {
      values: this.allValues[pageNumber - 1].values,
      labels: this.allValues[pageNumber - 1].labels,
    };
  }

  private unsubscribeFetch() {
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
  }
}
