import { FooterButtonsComponentModule } from '../../components/footer-buttons/footer-buttons.module';
import { CrowdSourcePage } from './crowdsource';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CrowdSourceRoutingModule } from './crowd-source-routing.module';
import { BottomLogoComponentModule } from '../../components/bottom-logo/bottom-logo.module';

@NgModule({
  declarations: [CrowdSourcePage],
  imports: [
    IonicModule,
    FooterButtonsComponentModule,
    CommonModule,
    FormsModule,
    CrowdSourceRoutingModule,
    BottomLogoComponentModule,
  ],
  exports: [CrowdSourcePage],
})
export class CrowdSourcePageModule {}
