import {
  transition,
  trigger,
  style,
  animate,
  state,
  keyframes,
} from '@angular/animations';

export const AppAnimations = [
  trigger('fadeInOut', [transition(':enter', [style({ opacity: 0 }), animate(500, style({ opacity: 1 }))]), transition(':leave', [animate(500, style({ opacity: 0 }))])]),

  trigger('bounce', [transition('false <=> true', [animate(
    1000,
    keyframes([
      style({
        opacity: 0,
        transform: 'translateY(0px)',
        offset: 0,
      }),
      style({
        opacity: 1,
        transform: 'translateY(-20px)',
        offset: 0.2,
      }),
      style({
        opacity: 1,
        transform: 'translateY(0px)',
        offset: 0.4,
      }),
      style({
        opacity: 1,
        transform: 'translateY(-10px)',
        offset: 0.6,
      }),
      style({
        opacity: 1,
        transform: 'translateY(0px)',
        offset: 0.8,
      }),
      style({
        opacity: 1,
        transform: 'translateY(-5px)',
        offset: 0.9,
      }),
      style({
        opacity: 1,
        transform: 'translateY(0px)',
        offset: 1.0,
      }),
    ])
  )])]),

  trigger('clickBlink', [transition('false => true', [animate(
    400,
    keyframes([style({
      backgroundColor: '#cfd8dc',
      transform: 'scale(0.9)',
    })])
  )])]),

  trigger('plusToMinus', [
    state('true', style({ transform: 'rotate(45deg)' })),
    state('false', style({ transform: 'rotate(0deg)' })),
    transition('* => true', animate('400ms linear')),
    transition('true => false', animate('400ms linear')),
  ]),

  trigger('hideShow', [
    state(
      'true',
      style({
        opacity: 0,
        display: 'none',
      })
    ),
    state(
      'false',
      style({
        opacity: 1,
        display: 'flex',
      })
    ),
    transition('true => false', [style({ opacity: 0 }), animate(500, style({ opacity: 1 }))]),
    transition('false => true', [animate(500, style({ opacity: 0 }))]),
  ]),

  trigger('toggleList', [
    state(
      'true',
      style({
        opacity: 1,
        display: 'block',
      })
    ),
    state(
      'false',
      style({
        opacity: 0,
        display: 'none',
      })
    ),
    transition('true => false', [style({ opacity: 1 }), animate(500, style({ opacity: 0 }))]),
    transition('false => true', [animate(500, style({ opacity: 1 }))]),
  ]),
];
