import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './app-logo.html',
  styleUrls: ['./app-logo.scss'],
})
export class AppLogoComponent implements OnInit {
  @Input() headerDisplay: any;
  logoText: string = '';

  ngOnInit(): void {
    this.logoText = 'MAG+';
  }
}
