import { InviewMainComponent } from '../../app_modules/inview/inview-main/inview-main';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleAnalyticsProvider } from '../../providers/google-analytics/google-analytics.service';
import { GATrackScreen } from '../../providers/google-analytics/google-analytics.interface';
import { Store } from '@ngrx/store';
import { AppState } from '~app.state';
import { clearInviewState } from '../../app_modules/inview/actions/inview.actions';

@Component({
  selector: 'inview-page',
  templateUrl: './inview.html',
  styleUrls: ['./inview.scss'],
})
export class InviewPage implements OnInit {
  @ViewChild(InviewMainComponent, { static: false })
  inview: InviewMainComponent;

  constructor(
    private googleAnalytics: GoogleAnalyticsProvider,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.googleAnalytics.trackScreen(GATrackScreen.MAG);
    this.store.dispatch(clearInviewState());
  }

}
