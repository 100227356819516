import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FacilitySelectionComponent } from './facility-selection';
import { FacilitySelectionSearchComponentModule } from './facility-selection-search/facility-selection-search.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [FacilitySelectionComponent],
  imports: [
    IonicModule,
    FacilitySelectionSearchComponentModule,
    CommonModule,
  ],
  exports: [FacilitySelectionComponent],
})
export class FacilitySelectionComponentModule {}
