import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { AreaSelectionComponent } from './area-selection.component';
import { MapInviewComponentModule } from '../map-inview/map-inview.module';
import { FacilitySelectionComponentModule } from '../facility-selection/facility-selection.module';

@NgModule({
  declarations: [AreaSelectionComponent],
  imports: [
    IonicModule,
    CommonModule,
    MapInviewComponentModule,
    FacilitySelectionComponentModule,
  ],
  exports: [AreaSelectionComponent],
})
export class AreaSelectionModule {}
