import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { BottomLogoComponentModule } from '../../components/bottom-logo/bottom-logo.module';
import { AboutPage } from './about.page';
import { AboutRoutingModule } from './about.routing.module';

@NgModule({
  declarations: [AboutPage],
  imports: [
    IonicModule,
    CommonModule,
    AboutRoutingModule,
    BottomLogoComponentModule,
  ],
  exports: [AboutPage],
})
export class AboutPageModule {}
