import {
  Component,
  OnDestroy,
  OnInit,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { InviewEndpoints, InviewRestApi } from '../../../../providers/inview-rest-api/inview-rest-api';
import { BaseReport } from '../../base-report.component';
import { ZipCodeChangeHandler } from '../../shared/zip-code-change-handler.service';
import { ZipCodeConsumerComponent } from '../../../../../../shared/models';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {
  chooseMarketAreaMessage,
  noDataAvailableExpandMarket,
} from '../../inview-reports.interface';
import { InviewReportsProvider } from '../../inview-reports-provider';

@Component({
  selector: 'demographic-snapshot',
  templateUrl: './demographic-snapshot.component.html',
  styleUrls: ['./demographic-snapshot.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class DemographicSnapshotComponent extends BaseReport
  implements OnInit, OnDestroy, ZipCodeConsumerComponent {
  public rows: string[][];
  public headers: string[];
  public showSpinner = false;
  public zipCodes: string[];
  public infoMessage: string;
  private dataSubscription: Subscription;

  constructor(
    private restApi: InviewRestApi,
    private zipCodeChangeHandler: ZipCodeChangeHandler,
    private reportsProvider: InviewReportsProvider,
    public host: ElementRef
  ) {
    super(host);
  }

  ngOnInit(): void {
    this.zipCodeChangeHandler.registerComponent(this);
  }

  ngOnDestroy() {
    this.zipCodeChangeHandler.unregisterComponent(this);
    this.unsubscribeFetchData();
  }

  unsubscribeFetchData() {
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
  }

  fetchData() {
    this.unsubscribeFetchData();
    this.infoMessage = null;
    this.headers = null;
    this.rows = null;
    if (this.zipCodes.length == 0) {
      this.infoMessage = chooseMarketAreaMessage;
    } else {
      this.showSpinner = true;
      this.dataSubscription = this.restApi
        .getReport(this.zipCodes, InviewEndpoints.demographic)
        .pipe(
          finalize(() => {
            this.showSpinner = false;
            this.ready.emit();
          })
        )
        .subscribe((response: any) => {
          if (this.reportsProvider.checkIfEmptyData(response)) {
            this.infoMessage = noDataAvailableExpandMarket;
          } else {
            this.headers = response.headers;
            this.rows = this.reportsProvider.mapSharedTablesRows(response.data);
          }
        });
    }
  }
}
