import * as moment from 'moment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateRange } from '../models';
import { Moment } from 'moment';
import { AppAnimations } from '~app.animations';

@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  animations: [AppAnimations],
})
export class DateRangePickerComponent implements OnInit {
  @Output() changed = new EventEmitter<DateRange>();
  @Input() opens: string = 'center';
  @Input() drops: string = 'down';
  @Input() defaultDateRange: DateRange;
  @Input() isClearVisible = true;

  public placeholderText = 'Select date range...';

  selected: DateRange;

  selectedMobile: string;


  ranges: {} = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'Last 6 Months': [moment().subtract(6, 'month'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Previous Month': [moment()
      .subtract(1, 'month')
      .startOf('month'),
    moment()
      .subtract(1, 'month')
      .endOf('month')],
  };

  ngOnInit(): void {
    if (this.defaultDateRange) {
      this.selected = this.defaultDateRange;
    }
  }

  invalidDates: moment.Moment[] = [];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  today(): Moment {
    return moment(new Date());
  }

  change() {
    this.changed.emit(this.selected);
  }

  clear() {
    this.selectedMobile = undefined;
    this.selected = undefined;
    this.changed.emit(this.selected);
  }
}
