import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminPanelPage } from './admin-panel';

const routes: Routes = [{
  path: '',
  component: AdminPanelPage,
},
{
  path: 'mag-analytics',
  loadChildren: () =>
        import('../mag-analytics/mag-analytics.module').then(
          (m) => m.MagAnalyticsPageModule
        ),
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminPanelRoutingModule {}
