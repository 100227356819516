import { Component, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { ZipCode } from '../map-inview/map-inview.interface';
import { InviewRestApi } from '../../providers/inview-rest-api/inview-rest-api';
import { AppState } from '~app.state';
import { InviewActions } from '../../actions';
import { setProceduresSpinner, setZipCodesSpinner } from '../../actions/inview.actions';
import { MapState } from '../../reducers/inview.reducer';

@Component({
  selector: 'facility-selection',
  templateUrl: './facility-selection.html',
  styleUrls: ['./facility-selection.scss'],
})
export class FacilitySelectionComponent implements OnDestroy {
  public mapState$: Observable<MapState>;
  private multipleZipcodeSubscription = new Subscription();
  public disableBtn;

  constructor(
    private inviewRestApi: InviewRestApi,
    private alertCtrl: AlertController,
    private store: Store<AppState>
  ) {
    this.mapState$ = this.store.select('inview').pipe(
      map((data) => data.map)
    );
  }

  ngOnDestroy() {
    this.multipleZipcodeSubscription.unsubscribe();
  }

  public deleteZipCode(zipCode) {
    this.store.dispatch(InviewActions.unselectZipCode({ zipCode }));
  }

  public deleteZipCodeAll(zipcodearr) {
    zipcodearr.forEach(zipCode => {
      this.store.dispatch(InviewActions.unselectZipCode({ zipCode }));    
      this.disableBtn = false;
    });   
  }

  sendZipCodeList(val) {
    this.store.dispatch(setProceduresSpinner({ isSpinner: true }));
    this.store.dispatch(setZipCodesSpinner({ isSpinner: true }));
    this.multipleZipcodeSubscription = this.inviewRestApi.getMultipleZipCodes(val).subscribe((res: any) => {
      this.store.dispatch(setZipCodesSpinner({ isSpinner: false }));
      if(!res.data) {
        this.presentAlert(res.message);
        return;
      }
      else {
        if(res.data.zipCodeList.length <= 150) {   
          this.chooseZipCodesList(res.data.zipCodeList);
          this.disableBtn = true;
        }
        else{
          this.presentAlert(res.message);
        }
        if(res.data.parsingErrors) {
          this.presentAlert(res.data.parsingErrors);
        }
      }
    },
    (err) => {
      this.store.dispatch(setProceduresSpinner({ isSpinner: false }));
      this.store.dispatch(setZipCodesSpinner({ isSpinner: false }));
      this.presentAlert(err);
    });
  }

  chooseZipCodesList(list: ZipCode[]) {
    this.store.dispatch(InviewActions.enterZipCodesList({ zipCodes: list }));
  }

  async presentAlert(message: string, title?) {
    const alert = await this.alertCtrl.create({
      subHeader: title ? title : 'Zip Codes',
      message,
      buttons: ['OK'],
    });
    await alert.present();
  } 

}
