import { Component } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'inview-main',
  templateUrl: './inview-main.html',
  styleUrls: ['./inview-main.scss'],
})
export class InviewMainComponent {

  constructor(
    private alertCtrl: AlertController
  ) {}

  openInfo(cardName) {
    let info = '';
    switch (cardName) {
      case 'Select Facility/Zip Code': {
        info =
          'Please select an account AND/OR market area (zip codes) to see data in them.';
        break;
      }
      case 'Map': {
        info = 'View and confirm your selections.';
        break;
      }
      case 'Service Categories': {
        info = 'Multi-select the service lines you wish to analyze.';
        break;
      }
    }
    this.presentAlert(info, cardName).then();
  }

  async presentAlert(message: string, title?) {
    const alert = await this.alertCtrl.create({
      subHeader: title ? title : 'Procedures',
      message,
      buttons: ['OK'],
    });
    await alert.present();
  }
}
