import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { BaseReport } from '../../base-report.component';
import { InviewEndpoints, InviewRestApi } from '../../../../providers/inview-rest-api/inview-rest-api';
import { ZipCodeChangeHandler } from '../../shared/zip-code-change-handler.service';
import { ZipCodeConsumerComponent } from '../../../../../../shared/models';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  chooseMarketAreaMessage,
  noDataAvailableExpandMarket,
} from '../../inview-reports.interface';
import { InviewReportsProvider } from '../../inview-reports-provider';
import {
  ComplexVerticalChartDataSet,
  VerticalChartType,
} from '../../../../../../shared/charts/vertical-chart/vertical-chart.interface';

@Component({
  selector: 'emergency-dept-visits-payer',
  templateUrl: './emergency-dept-visits-payer.component.html',
  styleUrls: ['./emergency-dept-visits-payer.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class EmergencyDeptVisitsPayerComponent extends BaseReport
  implements OnInit, OnDestroy, ZipCodeConsumerComponent {
  public currentPage: number = 1;
  public allValues: any[];
  public dataSetPage: ComplexVerticalChartDataSet;
  public zipCodes: string[];
  public showSpinner = false;
  public infoMessage: string;
  private dataSubscription: Subscription;
  VerticalChartType = VerticalChartType;

  constructor(
    private restApi: InviewRestApi,
    private zipCodeChangeHandler: ZipCodeChangeHandler,
    private reportsProvider: InviewReportsProvider,
    public host: ElementRef
  ) {
    super(host);
  }

  ngOnInit(): void {
    this.zipCodeChangeHandler.registerComponent(this);
  }
  ngOnDestroy(): void {
    this.zipCodeChangeHandler.unregisterComponent(this);
    this.unsubscribeFetchData();
  }

  unsubscribeFetchData() {
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
  }

  fetchData() {
    this.unsubscribeFetchData();
    this.infoMessage = null;
    this.dataSetPage = null;
    if (this.zipCodes && this.zipCodes.length > 0) {
      this.showSpinner = true;
      this.dataSubscription = this.restApi
        .getReport(this.zipCodes, InviewEndpoints.emergencyDeptVisitsPayer)
        .pipe(
          finalize(() => {
            this.showSpinner = false;
            this.ready.emit();
          })
        )
        .subscribe((response: any) => {
          if (this.reportsProvider.checkIfEmptyData(response)) {
            this.infoMessage = noDataAvailableExpandMarket;
          } else {
            this.dataSetPage = {
              values: response.data.dataset.values,
              labels: response.data.dataset.labels,
              footerCaption: response.data.dataset.footerCaption,
            };
          }
        });
    } else {
      this.infoMessage = chooseMarketAreaMessage;
    }
  }
}
