import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '~app.state';
import { ZipCodeConsumerComponent } from '../../../../../shared/models';
import { debounceTime, distinctUntilChanged, pluck } from 'rxjs/operators';
import { Subscription } from 'rxjs';


@Injectable()
export class ZipCodeChangeHandler {
  private components: Set<ZipCodeConsumerComponent> = new Set();
  private subs: Subscription;
  constructor(private readonly store: Store<AppState>) {}

  public registerComponent(component: ZipCodeConsumerComponent) {
    this.components.add(component);
    this.subs = this.zipCodeSubscription(component);
  }

  public unregisterComponent(component: ZipCodeConsumerComponent) {
    this.subs.unsubscribe();
    const componentsSet = this.components;
    componentsSet.forEach(function(c) {
      if (c.constructor == component.constructor) {
        componentsSet.delete(c);
      }
    });
  }

  zipCodeSubscription(c) {
    return this.store.select('inview')
      .pipe(
        pluck('map', 'selectedZipcodes'),
        distinctUntilChanged(),
        debounceTime(1000)
      )
      .subscribe( (data) => {
        c.zipCodes = data.map((zip) => zip.code);
        c.fetchData();
      });
  }
}
