import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-slide-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideLabelsComponent {
  @Input() colors: string[] = [];
  @Input() total: number = 0;
  @Input() definitionId: string = '';
  private _labels: string[] = [];
  @Input()
  set labels(v: string[]) {
    this._labels = v.filter((label) => !!label);
  }
  get labels() {
    return this._labels;
  }
}
