import { ProceduresComponent } from './procedures-selection';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { RegularButtonComponentModule } from '../../../../components/buttons/regular-button/regular-button.module';
import { SubProceduresModalComponent } from './sub-procedures-modal/sub-procedures-modal.component';
import { PreventDoubleClickDirective } from '../../helpers/preventDoubleClick.directive';

@NgModule({
  declarations: [
    ProceduresComponent,
    SubProceduresModalComponent,
    PreventDoubleClickDirective,
  ],
  imports: [
    IonicModule,
    CommonModule,
    RegularButtonComponentModule,
  ],
  exports: [ProceduresComponent],
  entryComponents: [SubProceduresModalComponent],
})
export class ProceduresComponentModule {}
