import { Component, Input } from '@angular/core';
import { ImageGenerator } from '../../app_modules/inview/helpers/imageGennerator';

@Component({
  selector: 'app-copy-btn',
  templateUrl: './copy-btn.component.html',
  styleUrls: ['./copy-btn.component.scss'],
})
export class CopyBtnComponent {

  @Input() refChart;
  imgSrc: string;

  constructor() {
  }

  onCreateImage() {
    const reportHost = this.refChart.host.nativeElement;

    ImageGenerator.create(
      this.refChart.createHTML(null, ''), // todo: add title to copy image
      reportHost.title
    ).then( (v) => {
      this.imgSrc = v.data;
    }).then(() => {
      this.copy();
      setTimeout(() => this.copy(), 2000);
    });

  }

  copy() {
    // Select the email link anchor text
    const imageElem = document.querySelector('.image-class');
    const range = document.createRange();
    range.selectNode(imageElem);
    window.getSelection().addRange(range);

    try {
      // Now that we've selected the anchor text, execute the copy command
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copy image command was ' + msg);
    } catch(err) {
      console.log('Oops, unable to copy');
    }
    // Remove the selections - NOTE: Should use
    // removeRange(range) when it is supported
    window.getSelection().removeAllRanges();
  }

}