import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  DisplayType,
  ProcedureAndZipCodeConsumerComponent,
  Procedures,
  ProcedureType,
} from '../../../../../../shared/models';
import { InviewEndpoints, InviewRestApi } from '../../../../providers/inview-rest-api/inview-rest-api';
import { StateChangeHandler } from '../../shared/state-change-handler.service';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {
  chooseMarketAreaProceduresMessage,
  noDataAvailableExpandMarket,
} from '../../inview-reports.interface';
import { InviewReportsProvider } from '../../inview-reports-provider';
import { BaseReport } from '../../base-report.component';
import {
  ComplexVerticalChartDataSet,
  VerticalChartType,
} from 'src/shared/charts/vertical-chart/vertical-chart.interface';

@Component({
  selector: 'inpatient-discharges-payer',
  templateUrl: './inpatient-discharges-payer.component.html',
  styleUrls: ['./inpatient-discharges-payer.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class InpatientDischargesPayerComponent extends BaseReport
  implements
    OnInit,
    OnDestroy,
    ProcedureAndZipCodeConsumerComponent {
  @Input() displayType: DisplayType = DisplayType.Number;

  public procedures: Procedures = {
    inpatientProcedures: [],
    outpatientProcedures: [],
  };
  public procedureType = ProcedureType.INPATIENT;
  public zipCodes: string[];
  public currentPage: number = 1;
  public allValues: any[];
  public showSpinner = false;
  public dataSetPage: ComplexVerticalChartDataSet;
  private dataSubscription: Subscription;
  public infoMessage: string;
  VerticalChartType = VerticalChartType;

  constructor(
    private restApi: InviewRestApi,
    private stateChangeHandler: StateChangeHandler,
    private reportsProvider: InviewReportsProvider,
    public host: ElementRef
  ) {
    super(host);
  }

  ngOnInit() {
    this.stateChangeHandler.registerComponent(this);
  }

  ngOnDestroy(): void {
    this.stateChangeHandler.unregisterComponent(this);
    this.unsubscribeFetchData();
  }

  unsubscribeFetchData() {
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
  }

  fetchData() {
    this.unsubscribeFetchData();
    this.infoMessage = null;
    this.allValues = null;
    if (
      this.zipCodes &&
      this.zipCodes.length > 0 &&
      this.procedures.inpatientProcedures.length > 0
    ) {
      this.showSpinner = true;
      this.dataSubscription = this.restApi
        .getInpatientProceduresReport(
          this.zipCodes,
          this.procedures.inpatientProcedures,
          InviewEndpoints.inpatientDischargesPayer
        )
        .pipe(
          finalize(() => {
            this.showSpinner = false;
            this.ready.emit();
          })
        )
        .subscribe((response: any[]) => {
          if (this.reportsProvider.checkIfEmptyPageableData(response)) {
            this.infoMessage = noDataAvailableExpandMarket;
          } else {
            this.allValues = response.map((res) => res.data.dataset);
            this.pageChanged(this.currentPage);
          }
        });
    } else {
      this.infoMessage = chooseMarketAreaProceduresMessage;
    }
  }

  pageChanged(pageNumber) {
    this.dataSetPage = this.allValues[pageNumber - 1];
  }
}
