import { Store } from '@ngrx/store';
import { Injectable, NgZone } from '@angular/core';
import { pluck } from 'rxjs/operators';
import {} from 'googlemaps';

import { InviewMapServiceNearestZipcodes } from './map-service-nearestzipcodes';
import { InviewMapServiceBounds } from './map-service-bounds';
import { Polygon, ZipCode } from '../../components/map-inview/map-inview.interface';
import { MapEvent, ZipCodeMapPolygon } from '../interfaces/map.interface';
import { InviewMapServiceLabels } from './map-service-labels';
import { AppState } from '~app.state';
import { InviewActions } from '../../actions';
import { MAX_ZIP_CODE_SELECTION_LIMIT } from '../interfaces/map.interface';

declare let google: any;

export type PolygonType = 'selected' | 'nearby';
export interface PolygonConfig {
  strokeColor?: string;
  strokeOpacity: number;
  strokeWeight: number;
  fillColor?: string;
  fillOpacity: number;
  zIndex: number;
}
export const CONFIG_POLYGON: {
  selected: PolygonConfig;
  nearby: PolygonConfig;
} = {
  selected: {
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillOpacity: 0.35,
    zIndex: 1000,
  },
  nearby: {
    strokeColor: '#cccccc',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#cccccc',
    fillOpacity: 0.35,
    zIndex: 100,
  },
};

@Injectable()
export class InviewMapServicePolygons {

  numberOfSelectedZipCodes = 0
  polygonsOnMap = [];

  constructor(
    private bounds: InviewMapServiceBounds,
    private _ngZone: NgZone,
    private nearest: InviewMapServiceNearestZipcodes,
    private labels: InviewMapServiceLabels,
    private store: Store<AppState>
  ) {
    this.store.select('inview').pipe(pluck('map'), pluck('selectedZipcodes'))
      .subscribe((selectedZipcodes: any[]) => {
        this.numberOfSelectedZipCodes = selectedZipcodes.length;
      });
  }

  private buildPolygon(
    polygon: Polygon[],
    type: PolygonType,
    inviewDataFilling: 'ALL' | 'PARTIAL' | 'NONE'
  ): google.maps.Polygon {
    let config = CONFIG_POLYGON[type] ? CONFIG_POLYGON[type] : {};
    const path = { paths: polygon };
    let color = {};
    if(type === 'selected') {
      switch(inviewDataFilling) {
        case 'NONE': {
          color = {
            fillColor: '#ed706b',
            strokeColor: '#ed706b',
          };
          break;
        }
        case 'PARTIAL': {
          color = {
            fillColor: '#f19d39',
            strokeColor: '#f19d39',
          };
          break;
        }
        default: {
          color = {
            fillColor: '#11a2d8',
            strokeColor: '#11a2d8',
          };
          break;
        }
      }
      config = Object.assign(config, color);
    }
    const shape = Object.assign(path, config);
    if (polygon.length > 0) {
      const polygon = new google.maps.Polygon(shape);
      return polygon;
    } else {
      console.warn('Polygon error: no outer shape available');
      return null;
    }
  }

  public showZipCodesPolygons(zipcodes: ZipCode[], type: PolygonType, map) {
    this.addManyZipCodePolygons(map, zipcodes, type);
  }

  private drawZipCodeShapes(
    map: google.maps.Map,
    zipcode: ZipCode,
    type: PolygonType
  ): google.maps.Polygon {
    const polygon: google.maps.Polygon = this.buildPolygon(
      zipcode.shapes,
      type,
      zipcode.inviewDataFilling
    );
    if (zipcode.center && type === 'selected') {
      this.bounds.addBound(zipcode.center);
    }
    this.addListenerTogglePolygon(polygon, zipcode, 'click', type);
    polygon.setMap(map);
    return polygon;
  }

  public removeZipCodePolygon(
    map: google.maps.Map,
    polygon: any,
    zipcode: ZipCode,
  ) {
    var polygonCode = zipcode.code;    
    if(polygon != undefined){
      const ind = this.polygonsOnMap.indexOf(polygon);
      this.polygonsOnMap.splice(ind, 1);
      this.bounds.removeBoundAndFitMap(map, zipcode.center, true);
      polygon.polygon.setMap(null);
    }else{
      polygon = polygonCode;
      const ind = this.polygonsOnMap.indexOf(polygon);
      this.bounds.removeBoundAndFitMap(map, zipcode.center, true);
    }    
  }

  private addListenerTogglePolygon(
    polygon: google.maps.Polygon,
    zipcode: ZipCode,
    mapEvent: MapEvent,
    type?: any
  ) {
    polygon.addListener(mapEvent, () => {
      this._ngZone.run(() => {
        if (this.numberOfSelectedZipCodes <= MAX_ZIP_CODE_SELECTION_LIMIT && type !== 'selected') {
          this.store.dispatch(InviewActions.selectZipCode({ zipCode: zipcode }));
        } else {
          this.store.dispatch(InviewActions.unselectZipCode({ zipCode: zipcode }));
        }
      });
    });
  }

  public addZipCodePolygon(map, zipcode: ZipCode, type: PolygonType) {
    const polygon: google.maps.Polygon = this.buildPolygon(
      zipcode.shapes,
      type,
      zipcode.inviewDataFilling
    );

    if (zipcode.center && type === 'selected') {
      this.polygonsOnMap.push({
        code: zipcode.code,
        polygon,
      });

      this.labels.showPolygonLabels(map, [{
        code: zipcode.code,
        polygons: polygon,
        center: zipcode.center,
      }]);
      this.bounds.addBound(zipcode.center);
      this.bounds.zoomOrFitBound(map);
    }

    this.addListenerTogglePolygon(polygon, zipcode, 'click', type);
    polygon.setMap(map);
  }

  private addManyZipCodePolygons(map, zipcodes: ZipCode[], type: PolygonType) {
    const drawnPolygons: ZipCodeMapPolygon[] = [];
    zipcodes.forEach((zipcode) => {
      const polygon = this.drawZipCodeShapes(map, zipcode, type);

      drawnPolygons.push({
        code: zipcode.code,
        polygons: polygon,
        center: zipcode.center,
      });
      if (zipcode.center && type === 'selected') {
        this.bounds.addBound(zipcode.center);
        this.polygonsOnMap.push({
          code: zipcode.code,
          polygon,
        });
        this.bounds.zoomOrFitBound(map);
      }
    });
    this.labels.showPolygonLabels(map, drawnPolygons);
  }
}
