import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { AppSessionStorage } from '../api-interfaces/rest.interface';

declare let sessionStorage: AppSessionStorage;

export interface RequestOptions {
  headers?: HttpHeaders;
  observe?: 'body' | 'response';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json' | 'blob' | 'arraybuffer' | 'text';
  withCredentials?: boolean;
  body?: any;
}

export const defaultOptions: RequestOptions = { responseType: 'json' };

@Injectable()
export class IHttp {
  constructor(private http: HttpClient) { }

  get<T>(
    url: string,
    options: RequestOptions = defaultOptions
  ): Observable<T> {
    if (!options.headers) {
      options.headers = new HttpHeaders();
    }
    options.headers = this.setHeaders(
      options.headers,
      sessionStorage,
      localStorage
    );

    if (sessionStorage.hrapiToken && url.includes('hrapi')) {
      options.headers.set(
        'Authorization',
        'Bearer ' + sessionStorage.hrapiToken
      );
    }
    // @ts-ignore
    return this.http.get<T>(url, options);
  }

  getBlobResponse<blob>(
    url: string,
    options: RequestOptions = { responseType: 'blob' },

  ): Observable<blob> {
    if (!options.headers) {
      options.headers = new HttpHeaders();
    }
    options.headers = this.setHeaders(
      options.headers,
      sessionStorage,
      localStorage
    );

    if (sessionStorage.hrapiToken && url.includes('hrapi')) {
      options.headers.set(
        'Authorization',
        'Bearer ' + sessionStorage.hrapiToken
      );
    }
    // @ts-ignore
    return this.http.get(url, options);
  }

  post<T>(
    url: string,
    body?: any,
    options: RequestOptions = defaultOptions
  ): Observable<T> {
    if (!options.headers) {
      options.headers = new HttpHeaders();
    }
    options.headers = this.setHeaders(
      options.headers,
      sessionStorage,
      localStorage
    );
    // @ts-ignore
    return this.http.post<T>(url, body, options);
  }

  delete<T>(
    url: string,
    options: RequestOptions = defaultOptions
  ): Observable<T> {
    if (!options.headers) {
      options.headers = new HttpHeaders();
    }
    options.headers = this.setHeaders(
      options.headers,
      sessionStorage,
      localStorage
    );
    // @ts-ignore
    return this.http.delete<T>(url, options);
  }

  private setHeaders(
    headers: HttpHeaders,
    sessionStorage,
    localStorage
  ): HttpHeaders {
    if (
      sessionStorage.access_token &&
      typeof sessionStorage.access_token !== 'undefined'
    ) {
      headers = headers.set(
        'Authorization',
        'Bearer ' + sessionStorage.access_token
      );
    }
    if (sessionStorage.id_token_claims_obj.sub && typeof sessionStorage.id_token_claims_obj.sub !== 'undefined') {
      headers = headers.set('SM_USER', JSON.parse(sessionStorage.id_token_claims_obj).sub);
    }
    if (
      (!sessionStorage.id_token_claims_obj.sub || typeof sessionStorage.id_token_claims_obj.sub === 'undefined') &&
      localStorage.sso &&
      typeof localStorage.sso !== 'undefined'
    ) {
      headers = headers.set('SM_USER', JSON.parse(sessionStorage.id_token_claims_obj).sub);
    }
    if (sessionStorage.email && typeof sessionStorage.email !== 'undefined') {
      headers = headers.set('email', sessionStorage.email);
    }
    if (localStorage.email && typeof localStorage.email !== 'undefined') {
      headers = headers.set('email', localStorage.email);
    }
    return headers;
  }
}
