import { InviewMainComponentModule } from './../../app_modules/inview/inview-main/inview-main.module';
import { BottomLogoComponentModule } from './../../components/bottom-logo/bottom-logo.module';
import { InviewPage } from './inview';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { InviewRoutingModule } from './inview-routing.module';
//import { OAuthModule } from 'angular-oauth2-oidc';

@NgModule({
  declarations: [InviewPage],
  imports: [
    IonicModule,
    BottomLogoComponentModule,
    InviewMainComponentModule,
    CommonModule,
    InviewRoutingModule,
    //OAuthModule.forRoot(),
  ],
  exports: [InviewPage],
})
export class InviewPageModule {}
