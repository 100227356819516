import { MatPaginatorIntl } from '@angular/material';
import { Injectable } from '@angular/core';

@Injectable()
export class SimplePaginator extends MatPaginatorIntl {
  getRangeLabel = function (page: number, pageSize: number, length: number) {
    if (length === 0 || pageSize === 0) {
      return '-';
    } else {
      return 'Page ' + (page + 1) + ' of ' + Math.ceil(length / pageSize);
    }
  };
}
