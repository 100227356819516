import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { RestMgmt } from '../rest-mgmt';
import { first } from 'rxjs/operators';
import {
  GAEventCategory,
  GATrackScreen,
} from './google-analytics.interface';
import { environment } from 'src/environments/environment.ts';

@Injectable()
export class GoogleAnalyticsProvider {
  private config: any = {
    stg: environment.googleAnalyticsId,
    prd: environment.googleAnalyticsId,
  };
  private readonly platform: 'Mobile' | 'Browser';
  private gaReady: ReplaySubject<boolean> = new ReplaySubject();
  public userID$ = new Subject();

  constructor(
    private ga: GoogleAnalytics,
    private restMgmt: RestMgmt
  ) {
    this.platform = 'Browser';
  }

  public setAccount(env: string = 'dev') {
    this.ga.startTrackerWithId(this.config[env]).then(
      () => {
        this.gaReady.next(true);
      },
      () =>
        console.warn(
          'Google Analytics tracker warn (doesn\'t work in ionic serve mode)'
        )
    );
    this.gaReady.pipe(first()).subscribe(() => {
      this.ga.addCustomDimension(1, this.platform);
      this.getUserID().subscribe((id) => {
        this.trackCustomDimension('User ID', id.toString());
        this.ga.setUserId(id.toString());
      });
    });
  }

  public trackScreen(
    screenTitle: string | GATrackScreen = GATrackScreen.HOME_PAGE,
    path: Array<string> = []
  ) {
    const screenName = this.formScreenName(screenTitle, path);
    this.gaReady.pipe(first()).subscribe(() => {
      this.ga.trackView(screenName);
    });
  }

  public trackEvent(
    eventCategory: GAEventCategory,
    eventAction: string,
    eventLabelPath: string,
    eventValue: number = 1
  ) {
    this.ga.trackEvent(eventCategory, eventAction, eventLabelPath, eventValue);
  }

  private formScreenName(pageName: string, path: Array<any> = []) {
    return pageName + (path.length > 0 ? '/' + path.join('/') : '');
  }

  private getUserID() {
    this.restMgmt.getUserGoogleAnalyticsID().subscribe(
      (res: string) => {
        this.userID$.next(res);
      },
      (err) => {
        console.error('Error, getting user ID ', err);
      }
    );
    return this.userID$.asObservable();
  }

  private trackCustomDimension(
    label: string,
    value: string | number | Date
  ): void {
    const index = this.getDimensionIndex(label);
    if (index) {
      this.ga.addCustomDimension(index, value.toString());
    }
  }

  private getDimensionIndex(label: string): number {
    let index: number;
    switch (label.toLowerCase()) {
      case 'user id':
        index = 2;
        break;
      case 'file name':
        index = 3;
        break;
      default:
        index = -1;
        break;
    }
    return index;
  }
}
