import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppAnimations } from '~app.animations';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './app-header.html',
  styleUrls: ['./app-header.scss'],
  animations: AppAnimations,
})
export class AppHeaderComponent {
  @Input() isAdmin: boolean = false;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly router: Router,
  ) {}
  navigateToRoot(path) {
    this.router.navigateByUrl(path,{ replaceUrl : true }).then();

  }

}
