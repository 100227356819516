import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
declare let window, document;

@Injectable()
export class StartupService {

  constructor(private platform: Platform) {}

  public load(): Promise<void> {
    return window.cordova
      ? Promise.race([this.ready(), this.platform.ready()])
      : Promise.resolve(null);
  }

  private ready(): Promise<void> {
    return new Promise((resolve) => {
      document.addEventListener('deviceready', resolve, false);
    });
  }
}
