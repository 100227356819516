import { Injectable } from '@angular/core';

import { InviewMapServiceLabels } from './map-service-labels';
import { InviewMapServiceNearestZipcodes } from './map-service-nearestzipcodes';
import { MAP_DEFAULT_SETTINGS } from './map.constants';
import { MapEvent } from './../interfaces/map.interface';

declare let google: any;


@Injectable()
export class InviewMapService {

  constructor(
    private nearest: InviewMapServiceNearestZipcodes,
    private labels: InviewMapServiceLabels
  ) {}

  /* Map management */

  public loadMap() {
    if (google === undefined || google.maps === undefined) {
      console.warn('Google maps JS needs to be loaded first...');
      return setTimeout(this.initMap(), 3000);
    } else {
      return this.initMap();
    }
  }

  public initMap() {
    const mapOptions = {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoom: MAP_DEFAULT_SETTINGS.initZoom,
      center: MAP_DEFAULT_SETTINGS.center,
      gestureHandling: 'cooperative', //'auto','greedy', 'none'
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    };
    // @ts-ignore
    const map = new google.maps.Map(document.getElementById('map-inview'), mapOptions);

    this.addListenerCenterChanged(map, 'idle');
    this.addListenerCenterChanged(map, 'center_changed');
    this.addListenerCenterChanged(map, 'zoom_changed');

    return map;
  }

  /* add listeners */

  private addListenerCenterChanged(map: google.maps.Map, mapEvent: MapEvent) {
    map.addListener(mapEvent, () => {
      switch (mapEvent) {
        case 'idle': {
          this.labels.zoomChange(
            map,
            MAP_DEFAULT_SETTINGS.minZoom.labels
          );
          break;
        }
        case 'zoom_changed': {
          const max = 11;
          map.getZoom() > max ? map.setZoom(max) : null;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

}
