import { NgModule } from '@angular/core';
import { TableComponent } from './table/table.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DateRangePickerComponent } from './datepicker/date-range-picker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { IonicModule } from '@ionic/angular';
import { MatTooltipModule } from '@angular/material';
import { CopyBtnComponent } from './copy-btn/copy-btn.component';

@NgModule({
  declarations: [
    TableComponent,
    DateRangePickerComponent,
    CopyBtnComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    IonicModule,
    MatTooltipModule,
  ],
  exports: [
    TableComponent,
    DateRangePickerComponent,
    CopyBtnComponent,
  ],
})
export class SharedComponentsModule {}
