import { Component, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { LoginService } from '../../providers/loginProviders/login-service';
import { GoogleAnalyticsProvider } from '../../providers/google-analytics/google-analytics.service';
import { Environment } from '~app.environment';


@Component({
  selector: 'init-page',
  templateUrl: 'init.html',
  styleUrls: ['./init.scss'],
})
export class InitPage implements OnDestroy {

  private akanaSubscription = new Subscription();
  private loginSuccessSub = new Subscription();

  ngOnDestroy(): void {
    this.akanaSubscription.unsubscribe();
    this.loginSuccessSub.unsubscribe();
  }

  constructor(
    private readonly loginService: LoginService,
    private readonly modalCtrl: ModalController,
    private readonly router: Router,
    private readonly ga: GoogleAnalyticsProvider,
  ) {
    this.loginSuccessSub = this.loginService.loginSuccessSource.subscribe(() => {
      this.ga.setAccount(Environment().AppEnvironment);
      this.router.navigate(['/mag'], { replaceUrl: true });
      this.loginService.isHeader$.next(true);
    });
  }
}
