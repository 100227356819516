import { Injectable } from '@angular/core';

import { InviewRestApi } from '../inview-rest-api/inview-rest-api';

@Injectable()
export class InviewFacilityService {
  private facilitiesResults = [];
  private zipCodesResults = [];

  constructor(private inviewRest: InviewRestApi) {}

  fetchFacilities(
    isNew: boolean = true,
    searchPhrase: string = '',
    offset: number = 0,
    limit: number = 10
  ) {
    return new Promise((resolve) => {
      this.inviewRest
        .getFacilities(searchPhrase, offset, limit)
        .subscribe((response) => {
          if (response['data']) {
            isNew
              ? (this.facilitiesResults = response['data'].facilities)
              : (this.facilitiesResults = this.facilitiesResults.concat(
                response['data'].facilities
              ));
            resolve({
              items: this.facilitiesResults,
              totalCount: response['data'].size,
            });
          }
          else {
            resolve({
              items: [],
              totalCount: 0,
            });
          }
        });
    });
  }

  fetchZipCodes(
    isNew: boolean = true,
    searchPhrase: string = '',
    offset: number = 0,
    limit: number = 10
  ) {
    return new Promise((resolve) => {
      this.inviewRest
        .getZipCodes(searchPhrase, offset, limit)
        .subscribe((response) => {
          if (response['data']) {
            if (response['data'].zipCodeList) {
              isNew
                ? (this.zipCodesResults = response['data'].zipCodeList)
                : (this.zipCodesResults = this.zipCodesResults.concat(
                  response['data'].zipCodeList
                ));
              resolve({
                items: this.zipCodesResults,
                totalCount: response['data'].size,
              });
            }
          } else {
            resolve({
              items: [],
              totalCount: 0,
            });
          }
        });
    });
  }
}
