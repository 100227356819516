import { pluck } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { GeoCoords } from './../interfaces/map.interface';
import { InviewRestApi } from './../inview-rest-api/inview-rest-api';
import { ZipCode } from '../../components/map-inview/map-inview.interface';
import { AppState } from '~app.state';
import { fetchZipCodesNearby } from '../../actions/inview.actions';

export const NearbyZipcodesConfig = { nearbyShowAtOnce: 30 };

@Injectable()
export class InviewMapServiceNearestZipcodes {
  private nearbyZipCodes: ZipCode[];

  constructor(
    private rest: InviewRestApi,
    private store: Store<AppState>
  ) {
    this.store.select('inview').pipe(pluck('map')).subscribe((map) => {
      this.nearbyZipCodes = map.nearbyZipcodes;
    });
  }

  public getNearestZipcodes(coords: GeoCoords) {
    this.store.dispatch(fetchZipCodesNearby({
      coords,
      showNumber: NearbyZipcodesConfig.nearbyShowAtOnce,
    }));
  }

  public showIfZoom(minZoom: number, map): boolean {
    const zoom = map.getZoom();
    return zoom >= minZoom;
  }
}
