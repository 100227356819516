import {
  EmergencyReport,
  InpatientReport, MarketShareReport,
  OutpatientReport,
  PopulationReport,
} from '../reports/inview-reports.interface';

export interface ReportSection {
  name: string;
  value: any;
  checkedAll: boolean;
}

export const ReportSections: ReportSection[] = [
  {
    name: 'population',
    value: PopulationReport,
    checkedAll: false,
  },
  {
    name: 'emergency',
    value: EmergencyReport,
    checkedAll: false,
  },
  {
    name: 'inpatient',
    value: InpatientReport,
    checkedAll: false,
  },
  {
    name: 'Procedures Volume',
    value: MarketShareReport,
    checkedAll: false,
  },
  {
    name: 'outpatient',
    value: OutpatientReport,
    checkedAll: false,
  },
];
