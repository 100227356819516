import { Injectable } from '@angular/core';

import { IHttp } from '../../../../providers/loginProviders/i-http';
import { GeoCoords } from '../../components/map-inview/map-inview.interface';
import { Environment } from '../../../../app/app.environment';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.ts';

export const InviewEndpoints = {
  insuranceCoverageTopZipCodes: 'insurance/topZipCodes',
  insuranceCoverage: 'insurance/coverage',
  insuranceCoveragePayerMixReport: 'insurance/payerMix',
  populationGrowth: 'zipCodes/populationGrowth',
  demographic: 'zipCodes/demographic',
  emergencyDeptProcedures: 'outpatient/emergency/procedures',
  ambulatorySurgeryProcedures5yGrowth: 'outpatient/surgery/growth',
  emergencyDeptVisitsPayer: 'outpatient/emergency/payers',
  populationGrowthAgeGender: 'zipCodes/population',
  inpatientDischarges: 'inpatient/discharges/growth',
  inpatientDischargesAge: 'inpatient/discharges/ageBands',
  inpatientDischargesPayer: 'inpatient/discharges/payerSplit',
  inpatientDaysGrowth: 'inpatient/days/growth',
  inpatientLengthOfStay: 'inpatient/discharges/averageLengthOfStay',
  inpatientVolumeDrivers: 'inpatient/procedure/breakdown',
  outpatientProceduresGrowth: 'outpatient/baseline/growth',
  outpatientProceduresAge: 'outpatient/procedures/age',
  outpatientVolumeDrivers: 'outpatient/procedures/breakdown',
  outpatientProceduresPayer: 'outpatient/procedures/payers',
  multiZipCodes: 'multiZipCodes?codes=',

};

@Injectable()
export class InviewRestApi {
  environment: any;

  endpointRoot: any = {
    dev: environment.bkndUrl,
    stg: environment.bkndUrl,
    prd: environment.bkndUrl,
  };

  constructor(public iHttp: IHttp) {
    this.environment = Environment().AppEnvironment;
  }

  getConfig(url: string): string {
    return `${this.endpointRoot[this.environment]}${url}`;
  }

  static parseGetFacilitiesUrl(name: string, offset?: number, limit?: number) {
    if (offset && limit) {
      return `facility?name=${name}&limit=${limit}&offset=${offset}`;
    } else {
      return `facility?name=${name}`;
    }
  }

  getFacilities(name, offset?, limit?) {
    return this.iHttp.get(
      this.getConfig(InviewRestApi.parseGetFacilitiesUrl(name, offset, limit))
    );
  }

  private parseGetZipCodesUrl(
    params: {
      code?: string;
      coords?: GeoCoords;
    },
    limitoffset: {
      limit: number;
      offset?: number;
    }
  ) {
    const _code: string = params.code ? 'code=' + params.code : '';
    const _coords: string = params.coords
      ? (_code ? '&' : '') +
        'lng=' +
        params.coords.lng +
        '&lat=' +
        params.coords.lat
      : '';
    const _limit = limitoffset.limit ? '&limit=' + limitoffset.limit : '';
    const _offset =
      limitoffset.limit && limitoffset.offset
        ? '&offset=' + limitoffset.offset
        : '';
    return 'zipCodes?' + _code + _coords + _limit + _offset;
  }

  public getZipCodes(code, offset?, limit?) {
    return this.iHttp.get(
      this.getConfig(
        this.parseGetZipCodesUrl(
          { code: code },
          {
            limit: limit,
            offset: offset,
          }
        )
      )
    );
  }

  getProcedures(zipCodesArray, facilityId?) {
    let body;
    if (facilityId) {
      body = {
        facilityId: facilityId,
        zipCodes: zipCodesArray,
      };
    } else {
      body = { zipCodes: zipCodesArray };
    }

    return this.iHttp.post(this.getConfig('procedures/available'), body)
      .pipe(map((response) => response['data'] ? { items: response['data'].procedures } : { items: [] }));
  }

  public getNearestZipCodes(coords: GeoCoords, limit: number) {
    return this.iHttp.get(
      this.getConfig(
        this.parseGetZipCodesUrl({ coords: coords }, { limit: limit })
      )
    );
  }

  getReport(zipCodes: string[], endpoint: string) {
    return this.iHttp.get(
      this.getConfig(endpoint) + this.zipCodesUrlParam(zipCodes)
    );
  }

  getPopulationGrowthAgeGender(zipCodes: string[], gender: string) {
    return this.iHttp.get(
      this.getConfig(InviewEndpoints.populationGrowthAgeGender) +
        this.zipCodesUrlParam(zipCodes) +
        '&gender=' +
        gender
    );
  }

  getSubProcedures(groupName: string, procedureName: string) {
    const body = {
      procedureGroup: groupName,
      name: procedureName,
    };

    return this.iHttp.post(this.getConfig('procedure/subs'), body)
      .pipe(map((response) => response['data'] ? { items: response['data'].subProcedures } : { items: [] }));
  }

  getInpatientProceduresReport(zipCodes: string[], procedures: string[], endpoint: string) {
    return this.iHttp.get(
      this.getConfig(endpoint) +
        this.zipCodesUrlParam(zipCodes) +
        this.andIPProcedureGroupsParam(procedures)
    );
  }

  getOutpatientProceduresReport(zipCodes: string[], procedures: string[], endpoint: string) {
    return this.iHttp.get(
      this.getConfig(endpoint) +
        this.zipCodesUrlParam(zipCodes) +
        this.andOPProcedureGroupsParam(procedures)
    );
  }

  getMultipleZipCodes(codes: string) {
    return this.iHttp.post(this.getConfig(InviewEndpoints.multiZipCodes), { codes });
  }

  getMarketShareDataByFacility(
    facilityId: string,
    inpatientProcedures: string[],
    outpatientProcedures: string[]
  ) {
    return this.iHttp.get(
      this.getConfig('marketShare/byFacility') +
        '?facilityId=' +
        facilityId +
        this.andIPProcedureGroupsParam2(inpatientProcedures) +
        this.andOPProcedureGroupsParam2(outpatientProcedures)
    );
  }

  getMarketShareDataByZipCodes(
    zipCodes: string[],
    inpatientProcedures: string[],
    outpatientProcedures: string[]
  ) {
    return this.iHttp.get(
      this.getConfig('marketShare/byZipCodes') +
        this.zipCodesUrlParam(zipCodes) +
        this.andIPProcedureGroupsParam2(inpatientProcedures) +
        this.andOPProcedureGroupsParam2(outpatientProcedures)
    );
  }

  uploadAndZip(body: FormData) {
    return this.iHttp.post(this.getConfig('export/uploadAndZip'), body, { responseType: 'blob' });
  }

  private zipCodesUrlParam(zipCodes: string[]) {
    return '?zipCodes=' + zipCodes.join();
  }

  private andIPProcedureGroupsParam(procedures: string[]) {
    return '&inpatientProcedureGroups=' + procedures.join();
  }

  private andOPProcedureGroupsParam(procedures: string[]) {
    return '&outpatientProcedureGroups=' + procedures.join();
  }

  private andIPProcedureGroupsParam2(procedures: string[]) {
    return '&ipProcedures=' + procedures.join();
  }

  private andOPProcedureGroupsParam2(procedures: string[]) {
    return '&opProcedures=' + procedures.join();
  }
}
