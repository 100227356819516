import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { BottomLogoComponentModule } from '../../components/bottom-logo/bottom-logo.module';
import { Error505Page } from './error-505.page';
import { Error505RoutingModule } from './error-505.routing.module';

@NgModule({
  declarations: [Error505Page],
  imports: [
    IonicModule,
    CommonModule,
    Error505RoutingModule,
    BottomLogoComponentModule,
  ],
  exports: [Error505Page],
})
export class Error505PageModule {}
