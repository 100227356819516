import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({ template: '' })
export class AdminPanelPage implements OnInit {

  loading: boolean = false;

  constructor(
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.router.navigateByUrl('admin-panel/mag-analytics',{ replaceUrl : true }).then();
  }

}
