import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartModule } from '../../../../shared/charts/chart.module';
import { FacilityMarketShareComponent } from './facility-market-share/facility-market-share';
import { ZipCodeMarketShareComponent } from './zip-code-market-share/zip-code-market-share';

@NgModule({
  declarations: [ZipCodeMarketShareComponent, FacilityMarketShareComponent],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ChartModule,
  ],
  providers: [],
  exports: [ZipCodeMarketShareComponent, FacilityMarketShareComponent],
})
export class MarketShareModule {}
