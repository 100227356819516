import { AppMainConfig } from './app.constants';
import { HostSettings, InternalExternalSettings } from './app.interface';
import { environment } from 'src/environments/environment.ts';

export const LocationHost = `${window.location.hostname}`;

const knownHosts: HostSettings[] = [
  {
    hostName: environment.hostNameUrl,
    env: 'stg',
    protocol: 'https:',
    port: '',
  },
  {
    hostName: environment.hostNameUrl,
    env: 'prd',
    protocol: 'https:',
    port: '',
  },  
];

export const HostEnvByName = (hostName) => {
  const host = knownHosts.find((host) => host.hostName === hostName);
  return host ? host.env : AppMainConfig.AppEnvironment;
};
const HostByEnv = (env) => {
  return knownHosts.find((host) => host.env === env);
};

export const ApiURL = (locHost) => {
  const hostEnv = HostEnvByName(locHost);
  const host = HostByEnv(hostEnv);
  const hostPort = host.port ? ':' + host.port : '';
  return host.protocol + '//' + host.hostName + hostPort + '/#/';
};

export const PlatformType = (locHost) => {
  switch (locHost) {
    case 'localhost':
      return 'local';
    case '':
      return 'local';

    default:
      return 'web';
  }
};

export const WidenEnv = (env) => {
  return env === 'dev' ? 'stg' : 'prd';
};
export const PlatformSets = (locHost) => {
  return {
    local: { ...AppMainConfig },
    web: {
      AppEnvironment: HostEnvByName(locHost),
      EnablePlatformReady: true,
      SecuredConnectionEnabled: true,
      DefaultUser: InternalExternalSettings.external.defaultUser,
    },
  };
};

export const Environment = (locHost = LocationHost) => {
  const platformType = PlatformType(locHost);
  return {
    ...PlatformSets(locHost)[platformType],
    WebHost: ApiURL(locHost),
    WidenEnv: WidenEnv(HostEnvByName(locHost)),
  };
};
