import { AlertController, ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable()
export class AlertsService {
  constructor(
    public alertCtrl: AlertController,
    private toastCtrl: ToastController
  ) {}

  async presentToast(msg: string) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'bottom',
    });
    await toast.present();
  }

  async presentAlert(message: string, title?, buttons?) {
    const alert = await this.alertCtrl.create({
      subHeader: title ? title : 'Home',
      message,
      buttons: buttons ? buttons : ['OK'],
    });
    await alert.present();
  }
}
