import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  ProcedureAndZipCodeConsumerComponent,
  Procedures,
  ProcedureType,
  TableModel,
} from '../../../../../../shared/models';
import { InviewEndpoints, InviewRestApi } from '../../../../providers/inview-rest-api/inview-rest-api';
import { StateChangeHandler } from '../../shared/state-change-handler.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  chooseMarketAreaProceduresMessage,
  noDataAvailableExpandMarket,
} from '../../inview-reports.interface';
import { InviewReportsProvider } from '../../inview-reports-provider';
import { BaseReport } from '../../base-report.component';

@Component({
  selector: 'inpatient-volume-drivers',
  templateUrl: './inpatient-volume-drivers.component.html',
  styleUrls: ['./inpatient-volume-drivers.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class InpatientVolumeDrivers extends BaseReport
  implements
    OnInit,
    OnDestroy,
    ProcedureAndZipCodeConsumerComponent {
  public tables: TableModel[];
  public procedures: Procedures = {
    inpatientProcedures: [],
    outpatientProcedures: [],
  };
  public procedureType = ProcedureType.INPATIENT;
  public zipCodes: string[];
  public showSpinner = false;
  private dataSubscription: Subscription;
  public infoMessage: string;
  public currentPage: number = 1;
  public currentTable: any;

  constructor(
    private restApi: InviewRestApi,
    private stateChangeHandler: StateChangeHandler,
    private reportsProvider: InviewReportsProvider,
    public host: ElementRef
  ) {
    super(host);
  }

  ngOnInit(): void {
    this.stateChangeHandler.registerComponent(this);
  }

  ngOnDestroy(): void {
    this.stateChangeHandler.unregisterComponent(this);
    this.unsubscribeFetchData();
  }

  unsubscribeFetchData() {
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
  }

  fetchData() {
    this.unsubscribeFetchData();
    this.infoMessage = null;
    this.tables = [];
    if (
      this.zipCodes &&
      this.zipCodes.length > 0 &&
      this.procedures.inpatientProcedures.length > 0
    ) {
      this.showSpinner = true;
      this.dataSubscription = this.restApi
        .getInpatientProceduresReport(
          this.zipCodes,
          this.procedures.inpatientProcedures,
          InviewEndpoints.inpatientVolumeDrivers
        )
        .pipe(
          finalize(() => {
            this.showSpinner = false;
            this.ready.emit();
          })
        )
        .subscribe((response: any[]) => {
          if (this.reportsProvider.checkIfEmptyPageableData(response)) {
            this.infoMessage = noDataAvailableExpandMarket;
          } else {
            this.tables = this.reportsProvider.mapResponseToTables(response);
            this.pageChanged(this.currentPage);
          }
        });
    } else {
      this.infoMessage = chooseMarketAreaProceduresMessage;
    }
  }

  pageChanged(pageNumber) {
    this.currentTable = this.tables[pageNumber - 1];
  }
}
