import { Component, ElementRef, Input } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { debounceTime, pluck } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { InviewFacilityService } from '../../../providers/inview-components-providers/facility-service';
import { MAX_ZIP_CODE_SELECTION_LIMIT } from '../../../providers/interfaces/map.interface';
import { GoogleAnalyticsProvider } from '../../../../../providers/google-analytics/google-analytics.service';
import { AppState } from '~app.state';
import { InviewActions } from '../../../actions';
import { Subject } from 'rxjs';


@Component({
  selector: 'facility-selection-search',
  templateUrl: 'facility-selection-search.html',
  styleUrls: ['./facility-selection-search.scss'],
  host: { '(document:click)': 'onDocumentClicked($event)' },
})
export class FacilitySelectionSearchComponent {
  @Input() placeholder: string = 'Search for...';
  @Input() inputType: string = 'facility';
  public searchFacility = null;
  public isListShow: boolean = false;
  public isFacilityValid: boolean = false;
  public facilityData;
  public zipCodeData;

  private facilitiesCount = 10;
  private facilitiesOffset = 0;
  private facilitiesTotalCount = 0;
  private prevFacility;
  private choosenFacility;
  private numberOfSelectedZipCodes = 0;
  public isSpinner: boolean = false;
  private searchPhrase$: Subject<string> = new Subject();



  onDocumentClicked(ev) {
    if (!this.elementRef.nativeElement.contains(ev.target)) {
      this.isListShow = false;
      if (this.choosenFacility) {
        this.searchFacility = this.choosenFacility.name;
        this.isFacilityValid = true;
      }
    }
  }

  constructor(
    private googleAnalyticsProvider: GoogleAnalyticsProvider,
    private facilityService: InviewFacilityService,
    private alertCtrl: AlertController,
    private elementRef: ElementRef,
    private store: Store<AppState>
  ) {
    this.store.select('inview').pipe(pluck('map'), pluck('selectedZipcodes'))
      .subscribe((selectedZipcodes: any[]) => {
        this.numberOfSelectedZipCodes = selectedZipcodes.length;
      });
  }

  ngOnInit() {
    this.searchPhrase$
      .pipe(debounceTime(1000))
      .subscribe(
        (v) => {
          this.handleKeyUp(v);
        }
      );
  }

  onClearFacility() {
    if (this.inputType == 'facility' && this.prevFacility) {
      this.store.dispatch(InviewActions.unselectFacility({ facility:this.choosenFacility }));
      this.searchFacility = null;
      this.prevFacility = null;
      this.choosenFacility = null;
      this.isFacilityValid = false;
    }
  }

  onKeyup(event) {
    this.searchPhrase$.next(event.target.value);
  }

  handleKeyUp(value){
    this.isFacilityValid = false;
    if (value.length < 3) {
      this.isListShow = false;
    } else if (value.length >= 3) {
      this.clearOffset();
      this.searchFacility = value;
      if (this.inputType === 'facility') {
        this.fetchFacilities(true);
      } else {
        this.fetchZipCodes(true);
      }
      this.isListShow = true;
    }
  }

  chooseZipCode(zipCode) {
    if (this.numberOfSelectedZipCodes <= MAX_ZIP_CODE_SELECTION_LIMIT) {
      this.searchFacility = zipCode.code;
      this.store.dispatch(InviewActions.selectZipCode({ zipCode }));
      this.isListShow = false;
      this.isFacilityValid = true;
    }
  }

  chooseFacility(facility) {
    if (this.numberOfSelectedZipCodes <= MAX_ZIP_CODE_SELECTION_LIMIT) {
      this.onClearFacility();
      this.choosenFacility = facility;
      this.searchFacility = facility.name;
      this.store.dispatch(InviewActions.selectFacility({ facility }));
      if (
        JSON.stringify(this.prevFacility) !=
          JSON.stringify(this.choosenFacility)
      )
        this.fetchZipCodes(true, facility.zipCode);
      this.prevFacility = facility;
      this.isListShow = false;
      this.isFacilityValid = true;
    }
  }

  fetchFacilities(isNew) {
    this.facilityService
      .fetchFacilities(
        isNew,
        this.searchFacility,
        this.facilitiesOffset,
        this.facilitiesCount
      )
      .then((res) => {
        this.facilityData = res['items'];
        this.facilitiesTotalCount = res['totalCount'];
        this.facilitiesOffset = this.facilitiesOffset + this.facilitiesCount;
      });
  }

  doInfinite(): Promise<any> {
    this.isSpinner = true;
    if (this.inputType === 'facility') {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (this.facilitiesOffset >= this.facilitiesTotalCount) {
            this.facilitiesCount = 0;
          } else if (
            this.facilitiesOffset + this.facilitiesCount >
            this.facilitiesTotalCount
          ) {
            this.facilitiesCount =
              this.facilitiesTotalCount - this.facilitiesOffset;
            this.fetchFacilities(false);
          } else {
            this.fetchFacilities(false);
          }
          resolve();
        }, 1000);
      }).then(() => (this.isSpinner = false));
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (this.facilitiesOffset >= this.facilitiesTotalCount) {
            this.facilitiesCount = 0;
          } else if (
            this.facilitiesOffset + this.facilitiesCount >
            this.facilitiesTotalCount
          ) {
            this.facilitiesCount =
              this.facilitiesTotalCount - this.facilitiesOffset;
            this.fetchZipCodes(false);
          } else {
            this.fetchZipCodes(false);
          }
          resolve();
        }, 1000);
      }).then(() => (this.isSpinner = false));
    }
  }

  fetchZipCodes(isNew, zipCode?) {
    if (zipCode) {
      this.facilityService.fetchZipCodes(isNew, zipCode).then((res) => {
        if (res['items'].length != 0)
          this.store.dispatch(InviewActions.selectZipCode({ zipCode: res['items'][0] }));
      });
    } else {
      this.facilityService
        .fetchZipCodes(
          isNew,
          this.searchFacility,
          this.facilitiesOffset,
          this.facilitiesCount
        )
        .then((res) => {
          this.zipCodeData = res['items'];
          this.facilitiesTotalCount = res['totalCount'];
          this.facilitiesOffset = this.facilitiesOffset + this.facilitiesCount;
        });
    }
  }

  clearOffset() {
    this.facilitiesCount = 10;
    this.facilitiesOffset = 0;
  }

  async presentAlert(message: string) {
    const alert = await this.alertCtrl.create({
      subHeader: 'Inview',
      message,
      buttons: ['OK'],
    });
    await alert.present();
  }
}
