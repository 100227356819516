import { LoaderService } from '../../providers/loader-service';
import { Component } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { CrowdMgmt } from '../../providers/crowd-mgmt';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { GoogleAnalyticsProvider } from '../../providers/google-analytics/google-analytics.service';
import { GATrackScreen } from '../../providers/google-analytics/google-analytics.interface';

@Component({
  selector: 'crowdsource-page',
  templateUrl: './crowdsource.html',
  styleUrls: ['./crowdsource.scss'],
})
export class CrowdSourcePage {
  public requestText: string = '';
  public subject: string = '';
  public isLoading: boolean = false;
  public cardHeaderText: string;
  public placeholderText: string;
  private selectedCategoryId: string;

  constructor(
    public toast: ToastController,
    public crowdMgmt: CrowdMgmt,
    public loader: LoaderService,
    private googleAnalytics: GoogleAnalyticsProvider,
    private activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    // this.googleAnalytics.trackScreen(GATrackScreen.CROWDSOURCE);
    // this.activatedRoute.queryParamMap.subscribe((params) => {
    //   this.selectedCategoryId = params.get('selectedCategoryId');
    //   if (params.get('selectedCategoryName')) {
    //     this.cardHeaderText =
    //       'Request information for: ' + params.get('selectedCategoryName');
    //     this.placeholderText = 'Enter message to Modality owners';
    //   } else {
    //     this.cardHeaderText = 'Request for Support:';
    //     this.placeholderText =
    //       'Request application support and information related to customer segment and market insights using this form. To request modality-specific information, please use the "Request Info" feature in the Modality Tile on the home page.';
    //   }
    // });
  }

  goBack() {
    this.router.navigateByUrl('/mag').then();
  }

  sendRequest() {
    if(this.subject != "" && this.requestText != ""){
      this.isLoading = true;
      this.loader.present();
      this.crowdMgmt.sendRequest(this.subject, this.requestText).then(
        (res: any) => {
          this.handleSuccess(res.response);
          this.requestText = "";
          this.subject = "";
        },
        (err: HttpErrorResponse) => {
          this.handleError(err);
        }
      );
    }else{
      this.presentToast("Please enter the subject and message.");
    } 
  }

  handleSuccess(message: string) {
    this.isLoading = false;
    this.loader.dismiss();
    this.presentToast(message);
    setTimeout(() => {
      this.goBack();
    }, 2400);
  }

  handleError(err: HttpErrorResponse) {
    this.isLoading = false;
    this.loader.dismiss();
    this.presentToast(err.error.message);
  }

  async presentToast(message: string) {
    const crowdToast = await this.toast.create({
      message: message,
      duration: 1500,
      position: 'bottom',
    });
    await crowdToast.present();
  }

  // buttonClicked(button: string) {
  //   if (button === 'Send') this.sendRequest();
  //   if (button === 'Cancel') this.goBack();
  // }
}
