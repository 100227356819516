import { MapInviewComponent } from './map-inview';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [MapInviewComponent],
  imports: [IonicModule, CommonModule],
  exports: [MapInviewComponent],
})
export class MapInviewComponentModule {}
