import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { InitPage } from './init';
import { CommonModule } from '@angular/common';
import { InitRoutingModule } from './init-routing.module';
import { BottomLogoComponentModule } from '../../components/bottom-logo/bottom-logo.module';

@NgModule({
  declarations: [InitPage],
  imports: [
    IonicModule,
    CommonModule,
    InitRoutingModule,
    BottomLogoComponentModule,
  ],
})
export class InitPageModule {}
