import { Component, OnInit } from '@angular/core';
import { ApiResponse, MagAnalyticsReport, MagAnalyticsReportPage } from '../../providers/api-interfaces/rest.interface';
import { AlertsService } from '../../providers/alerts-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateRange } from '../../shared/models';
import { Moment } from 'moment';
import * as moment from 'moment';
import { PageEvent } from '@angular/material';
import { SharedTableDataSupplier } from '../../shared/interfaces/shared-table-data-supplier';
import { RestMgmt } from '../../providers/rest-mgmt';

@Component({
  selector: 'mag-analytics-page',
  templateUrl: './mag-analytics.html',
  styleUrls: ['./mag-analytics.scss'],
})
export class MagAnalyticsPage
implements OnInit, SharedTableDataSupplier<MagAnalyticsReport> {
  private myForm: FormGroup;
  private readonly dateFormat = 'YYYY-MM-DD';
  public readonly defaultDateRange: DateRange = {
    startDate: moment().subtract(29, 'days'),
    endDate: moment(),
  };
  isLoading: boolean = true;

  pageIndex: number = 0;
  length: number = 0;
  pageSize: number = 10;
  pageEvent: PageEvent;

  readonly headers: string[] = [
    'ID',
    'Name',
    'Title',
    'SSO',
    'Facilities',
    'State or Zone',
    'Department',
    'Job Function',
    'Manager\'s SSO',
  ];
  rows: string[][] = [];

  constructor(
    public restMgmt: RestMgmt,
    public alertService: AlertsService,
    private formBuilder: FormBuilder,
  ) {}

  get startDate(): string {
    return (this.myForm.get('startDate').value as Moment).format(
      this.dateFormat
    );
  }

  get endDate(): string {
    return (this.myForm.get('endDate').value as Moment).format(this.dateFormat);
  }

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      startDate: [moment().subtract(29, 'days'), Validators.required],
      endDate: [moment(), Validators.required],
    });
  }

  onDateRangeChanged(event: DateRange) {
    this.myForm.patchValue({ startDate: event.startDate });
    this.myForm.patchValue({ endDate: event.endDate });
    this.pageIndex = 0;
    this.length = 0;
    this.rows = [];
    if (this.myForm.valid) {
      this.getReport({
        pageIndex: this.pageIndex,
        length: this.length,
        pageSize: this.pageSize,
      });
    }
  }

  getReport(pageEvent: PageEvent): PageEvent {
    this.isLoading = true;
    // backend starts from 1, not 0
    pageEvent.pageIndex += 1;

    this.restMgmt
      .getMagAnalyticsReport(pageEvent.pageIndex, this.startDate, this.endDate)
      .subscribe(
        (res: ApiResponse<MagAnalyticsReportPage>) => {
          this.length = res.data.totalElements;
          this.pageIndex = res.data.page;
          this.isLoading = false;

          let position = 0;
          if (res.data.items) {
            this.rows = res.data.items.map((reportRow) => {
              position++;
              const rowID = this.calculateRowID(
                position,
                pageEvent.pageIndex,
                pageEvent.length
              );
              return this.unwrap(reportRow, rowID);
            });
          } else {
            this.rows = [];
          }
        },
        (err) => {
          this.isLoading = false;
          this.alertService.presentToast(err + 'Error');
        }
      );
    return pageEvent;
  }

  unwrap(rowData: MagAnalyticsReport, rowID: number): string[] {
    return [
      rowID.toString(),
      rowData.user ? rowData.user.fullName : 'N/A',
      rowData.user ? rowData.user.positionTitle : 'N/A',
      rowData.user ? rowData.user.sso : 'N/A',
      rowData.selectedFacilities,
      rowData.user && rowData.user.country ? rowData.user.country + ', ' + rowData.user.stateOrProvince : 'N/A',
      rowData.user ? rowData.user.department : 'N/A',
      rowData.user ? rowData.user.jobFunction : 'N/A',
      rowData.user ? rowData.user.managerSso : 'N/A',
    ];
  }

  calculateRowID(position, pageIndex, length: number): number {
    if (length / pageIndex <= 1) {
      return position;
    } else {
      return pageIndex * this.pageSize + position - this.pageSize;
    }
  }

  getExcelReport() {
    this.restMgmt.getMagAnalyticsExcel(this.startDate, this.endDate);
  }
}
