import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AdminPanelPage } from './admin-panel';
import { AppHeaderComponentModule } from '../../components/app-header/app-header.module';
import { FooterButtonsComponentModule } from '../../components/footer-buttons/footer-buttons.module';
import { BottomLogoComponentModule } from '../../components/bottom-logo/bottom-logo.module';
import { CommonModule } from '@angular/common';
import { AdminPanelRoutingModule } from './admin-panel-routing.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AdminPanelPage],
  imports: [
    IonicModule,
    AppHeaderComponentModule,
    FooterButtonsComponentModule,
    BottomLogoComponentModule,
    CommonModule,
    FormsModule,
    AdminPanelRoutingModule,
  ],
})
export class AdminPanelPageModule {}
