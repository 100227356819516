import {
  MainConfig,
  InternalExternalSettings,
} from './app.interface';
import { VERSION } from './version';

export const LocalhostSettings = {
  EnablePlatformReady: true,
  DefaultUser: InternalExternalSettings.local.defaultUser,
};

export const AppMainConfig: MainConfig = {
  AppName: 'MAG+',
  AppVersion: VERSION.semver.version || '',
  AppEnvironment: 'prd',
  //config for localhost
  ...LocalhostSettings,
};

