import { AppLogoComponentModule } from './app-logo/app-logo.module';
import { AppHeaderComponent } from './app-header';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material';

@NgModule({
  declarations: [AppHeaderComponent],
  imports: [
    IonicModule,
    AppLogoComponentModule,
    CommonModule,
    RouterModule,
    MatTooltipModule,
  ],
  exports: [AppHeaderComponent],
})
export class AppHeaderComponentModule {}
