import { Component, Input, OnChanges } from '@angular/core';
import { ChartComponent } from '../shared/chart.component';
import { DisplayType } from '../../models';
import {
  ComplexVerticalChartDataSet,
  DARK_BLUE_BAR_COLOR,
  HOVER_DARK_BLUE_BAR_COLOR,
  HOVER_LIGHT_BLUE_BAR_COLOR,
  LIGHT_BLUE_BAR_COLOR,
  SimpleVerticalChartDataSet,
  VERTICAL_THRESHOLD,
  VerticalChartType,
} from './vertical-chart.interface';
import * as R from 'ramda';
import { ChartDataSets } from 'chart.js';

@Component({
  selector: 'vertical-chart',
  templateUrl: './vertical-chart.component.html',
  styleUrls: ['./vertical-chart.component.scss'],
})
export class VerticalChartComponent extends ChartComponent
  implements OnChanges {
  @Input() displayType: DisplayType = DisplayType.Number;
  @Input() isYScalabe = false;
  @Input() chartType: VerticalChartType;
  @Input() dataSet: SimpleVerticalChartDataSet | ComplexVerticalChartDataSet;

  public barPluginsConfig: Chart.ChartPluginsOptions = {
    datalabels: {
      rotation: 300,
      anchor: 'end',
      align: 'top',
      font: {
        size: 14,
        weight: 'bold',
      },
      formatter: (value: string) => `${value}` + this.getSuffix(),
      textStrokeColor: '#FFF',
      textStrokeWidth: 3,
      textShadowBlur: 4,
      textShadowColor: '#FFF',
    },
  };

  public labelsPluginsConfig: Chart.ChartPluginsOptions = {
    datalabels: {
      anchor: 'center',
      align: 'center',
      font: {
        size: 14,
        weight: 'bold',
      },
      formatter: (value: string) => parseInt(value) > VERTICAL_THRESHOLD ? `${value}` + this.getSuffix() : '',
      textStrokeColor: '#FFF',
      textStrokeWidth: 3,
      textShadowBlur: 4,
      textShadowColor: '#FFF',
    },
  };

  async ngOnChanges() {
    this.setDataSet();
    return this.setUp('', this.getSuffix(), this.pluginsConfig);
  }

  private setDataSet() {
    switch (this.chartType) {
      case VerticalChartType.GROUPED: {
        this.pluginsConfig = this.barPluginsConfig;
        this.setGroupedChartDataSet(this
          .dataSet as ComplexVerticalChartDataSet);
        break;
      }
      case VerticalChartType.SIMPLE: {
        this.colors = undefined;
        this.pluginsConfig = this.barPluginsConfig;
        this.setSimpleChartDataSet(this.dataSet as SimpleVerticalChartDataSet);
        break;
      }
      case VerticalChartType.STACKED_LABELS: {
        this.pluginsConfig = this.labelsPluginsConfig;
        this.setStackChartDataSet(this.dataSet as ComplexVerticalChartDataSet);
        break;
      }
      case VerticalChartType.STACKED: {
        this.setStackChartDataSet(this.dataSet as ComplexVerticalChartDataSet);
        break;
      }
    }
  }

  private setSimpleChartDataSet(dataSet: SimpleVerticalChartDataSet) {
    if (dataSet.labels) this.labels = dataSet.labels;
    this.chartLabels = dataSet.values.map(({ title }) => title);
    this.datasets = [{
      data: dataSet.values.map((value) => value.value),
      backgroundColor: dataSet.values.map(
        (value) =>
          value.palette == 'negative'
            ? DARK_BLUE_BAR_COLOR
            : LIGHT_BLUE_BAR_COLOR
      ),
      hoverBackgroundColor: dataSet.values.map(
        (value) =>
          value.palette == 'negative'
            ? HOVER_DARK_BLUE_BAR_COLOR
            : HOVER_LIGHT_BLUE_BAR_COLOR
      ),
      hoverBorderColor: '#fff',
    }];
  }

  private setStackChartDataSet(dataSet: ComplexVerticalChartDataSet) {
    this.footerLabels = dataSet.values.map(({ footer }) => footer);
    this.titleLabel = dataSet.footerCaption;
    this.labels = dataSet.labels;
    this.chartLabels = dataSet.values.map(({ name }) => name);
    const set = this.reduceSet(dataSet);
    this.datasets = dataSet.labels.map((title) => ({
      stack: 'true',
      title,
      label: title,
      data: this.chartLabels.map((period) => set[period][title] || 0),
    }));
  }

  private setGroupedChartDataSet(dataSet: ComplexVerticalChartDataSet) {
    this.footerLabels = dataSet.values.map(({ footer }) => footer);
    this.titleLabel = dataSet.footerCaption;
    this.labels = dataSet.labels;
    this.chartLabels = dataSet.values.map(({ name }) => name);
    const set = this.reduceSet(dataSet);
    this.datasets = dataSet.labels.map((title) => ({
      label: title,
      data: this.chartLabels.map((period) => set[period][title] || 0),
    }));
    this.scaleYxis();
  }

  private scaleYxis() {
    const pointsArray = R.pipe<ChartDataSets, any, number[], number[]>(
      R.prop('data'),
      R.defaultTo([]),
      R.filter(Boolean)
    )(this.datasets[0]);
    let myMin = Math.min(...pointsArray);
    if (myMin && this.isYScalabe) {
      myMin = Math.floor((myMin - 1) / 10) * 10;
      this.minYAxis = myMin;
    }
  }

  private getSuffix(): string {
    return this.displayType == DisplayType.Perc ? '%' : '';
  }
  private reduceSet(dataSet) {
    return dataSet.values.reduce((prev1: any, { name, data }) => {
      prev1[name] = data.reduce((prev2: any, { title = '', value }) => {
        prev2[title] = value;
        return prev2;
      }, {});
      return prev1;
    }, {});
  }
}
